$thm-blue :#001655;
$thm-green:#68b90b;
$thm-yellow:#ffff00;
$thm-orange:#ffa300;
body{
  background: url('../images/body-bg.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  &::-webkit-scrollbar-thumb {
    background: #888; 
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
}
.form-control{
  background-color: #f9f9f9 ;
    border: 1px solid #c5cad2!important;
    height: calc(1.8em + 0.9rem + 2px);
    &:focus{
      background-color: #fff!important;
    }
}

.btn-primary{
  background-color: $thm-yellow;
  border:1px solid  $thm-yellow;
  color: #000;
  border-radius: 30px;
  padding-left: 30px;
  padding-right: 30px;
  text-transform: capitalize;
  box-shadow: 5px 2px 3px rgba(0,0,0,0.3);
  &:hover,&:focus{
    background-color: #ffcc00;
    border:1px solid  #ffcc00;
    color: #000;
  }
}

.card.transparent{
  background-color: transparent;
  border:0px;
}
.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(2.1rem + 2px);
  line-height: 1.25;
  // background-color: #fff!important;
  background-color: rgba(20,55,226,0.2)!important;
  border:0px!important;
  border-radius: 20px;
  color: #fff;
}
.form-floating > .backtra{background-color: transparent !important;}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  color:#fff;
  height: 100%;
  padding: 7px 10px 10px 15px;
  font-size: 12px;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.600rem;
  padding-bottom: 0.600rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.600rem;
  padding-bottom: 0.600rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.600rem;
  padding-bottom: 0.600rem;
}
.form-floating > .form-select {
  border:0px!important;
  // padding-bottom: 0.625rem;
  &:focus-visible,&:focus{
    border-color:inherit!important;
  }
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.col-form-label{
  color: #fff;
}
.radio label{
  color: #fff
}
.tontxt{
  position: absolute;
    top: 7px;
    color: #dcd8d8;
    right: 36px;
}
.form-floating .form-select option{
  color: #333!important;
  line-height: 20px;
}
.bootstrap-touchspin{
  width: 160px;
  .input-group-btn{
    height: 34px;
     background-color: rgba(151,140,140,0.5)!important;
     color: #fff;
  }
  .input-group-prepend{
    border-radius: 20px 0px 0px 20px;
  }
  .input-group-append{
    border-radius: 0px 20px 20px 0px;
  }
  .form-control{
    height: 34px;
  }
  .btn-primary{
    background-color: transparent!important;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
     line-height: 10px;
     border:0px!important;
     box-shadow: inherit!important;
     color: #fff;
    &:hover,&:focus{
      background-color: $thm-green!important;
    }
  }
}
.form-floating>.form-select{
  padding-left: 10px;
  padding-right: 10px;
  background-position:  right 30px!important;
}

.dropdown:hover>.dropdown-menu {
    display: block;
}
.dropdown-item{
  color: #333!important;
}
.navbar-custom .topnav-menu .nav-link{
  color: #fff!important;
  font-size: 16px;
}

ul.stepsnomenu{
  margin:0px;
  padding:20px 0px 0px 0px;
  list-style: none;
  display: flex;
  flex-direction: column;
  li{
    text-align: right;
    position: relative;
    padding:20px 40px 20px 0px;
    a{
      color: #fff;
      font-size: 13px;
      // font-weight: 200;
      &:hover{
        color: $thm-yellow;
      }
      span{
         position: absolute;
         top:12px;
         right:-20px;
        &.circle{
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: block;
          background-color: #fff;
          border:1px solid #7fd61b;
          color: #d4d4d4;
          text-align: center;
          line-height: 40px;
          font-size: 20px;
          font-weight: 400;
          &:hover,&.active{
            background-color: $thm-yellow;
            color: #000;
          }
          &.completed{
            background-color: transparent;
            color: #fff;
          }
        }
      }
    }
    &.compstep a{
      color: $thm-yellow;
    }
  }
}
.form-floating>.form-select{
  -webkit-appearance: none;
   -moz-appearance:    none;
   appearance:         none;
   background: url('../images/angle-down.svg') no-repeat;
   background-position: top 10px right 14px!important;
   background-size: 10px;
   font-size: 12px;
}
.form-floating input[type='file']{
  font-size: 12px;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: $thm-green;
    border-color: $thm-green;
}
.yellowcol{
  color: $thm-yellow!important;
  font-size: 16px;
}
.rdbg{
  background-color: rgba(0,0,0,0.3); 
  padding:10px 10px 1px 20px; 
  border-radius: 30px; 
  border:1px solid #000;
  margin-bottom: 10px;
}
.table td{
  padding:0.65rem 0.65rem 0.40rem 0.65rem;
}
.table th{
  padding: 0.65rem;
}
.table .table-blk{
  background-color: rgba(0,0,0,0.2);
  border-collapse: collapse;
}
.table thead th,.table td{
  border:0px!important;
  vertical-align: middle!important;
  color: #fff;
}
.bg-success{
  background: $thm-green!important;
  color: #fff;
}
.page-title.fz-16{
  font-size: 16px!important;
  line-height: 24px;
  padding-bottom: 10px;
}
.page-title a{
  color: #fff;
  border:1px solid #fff;
  border-radius: 5px;
  font-size: 16px;
  padding:10px;
  &:hover{
    color: $thm-yellow
  }
}
.yellowcol{
  color: #fff!important;
  font-size: 16px;
  font-weight: 600;
}
.desoprbox{
  text-align: center;
  color: #fff;
  width: 250px!important;
  height: 90px;
  background-color: rgba(20,55,226,0.2);
  vertical-align: middle;
  display: table-cell;
  border:2px solid transparent;
  border-radius: 20px;
  p{
    margin:0px;
    padding:0px;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
  }
  i{
    font-size: 30px;
    color: $thm-green;
  }
}
.desoprbox.orange{
  border:2px solid #ff9600;
}
.desoprbox.red{
  border:2px solid #f6531f;
}
.desoprbox:hover{
  p{
    color: $thm-yellow;
  }
  i{
    color: $thm-yellow;
  }
}
a.checkactive{
  position: relative;
}
a.checkactive .active{
  border:4px solid #68b70b;
  &:after{
    content: "\e83f";
    font-family: 'feather'!important;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 20px;
    border-radius:50%;
    background-color: $thm-green;
    position: absolute;
    right:-13px;
    bottom:35px; 
    border:2px solid transparent;
  }
}
a.checkactive .desoprbox{
  background-color: rgba(20,55,226,0.2);
}
.btn-pryellow{
  background-color: #fcff00!important;
  border-color: #fcff00!important;
  border-radius: 30px;
  font-weight: 500;
  color:#000;
}
.prj .btn:hover{
  color:#000;
}
.trspbg{
    height: calc(2.1rem + 2px);
    line-height: 1.25;
    background-color: rgba(20,55,226,0.2) !important;
    border: 0px !important;
    border-radius: 20px;
    color: #fff!important;
    &:focus{
      background-color: rgba(151,140,140,0.5) !important;
    }
}
.logo-two{
  float: right;
  margin:20px 10px;
  img{
    height: 60px;
  }
}
.button-menu-mobile{
  z-index: 99;
  border:0;
  background-color: inherit;
  color: #fff;
  font-size: 30px;
  visibility: hidden;
}
.sdlogo{
  display: none;
}
.trasparent-box{
  background-color: rgba(0,0,0,0.3);
  border-radius: 10px;
  padding:20px;
  h3{
    font-size: 20px;
  }
  h4{
    text-align: center;
  }
  h5{
    font-size: 24px;
    font-weight: 300;
  }
  .border-bottom{
    border-bottom: 1px solid rgba(255,255,244,0.2)!important;
  }
}

.yellowcol{
  color: $thm-yellow!important;
}
.resultproject{
  color: #fff;
  h4{
    color: $thm-yellow;
  }
  p{
    color: #fff;
    margin-bottom: 0px;
  }
}
ul.cmsmenu{
  margin:0px;
  padding:10px 0px 20px 0px;
  list-style: none;
  li{
    margin:0px;
    padding:0px;
    display: inline-block;
    padding-right: 10px;
    a{
      color: #fff;
      border: 1px solid #fff;
      border-radius: 5px;
      font-size: 16px;
      padding: 10px 30px;
      &:hover{
       background-color: $thm-yellow;
      color: #000;
      border-color: $thm-yellow;
      }
    }
    &.active a{
      background-color: $thm-yellow;
      color: #000;
      border-color: $thm-yellow;
    }
  }
}
.fz-20{
  font-size: 14px;
}
.dashstats{
  .card-body{
    padding:1rem;
  }
}
h1.g-grade{
  font-size: 60px;
  line-height: 48px;
}
.gradeA{
  width: 80px;
  height: 70px;
  background-color: rgba(0,0,0,0.3);
  position: relative;
   font-size: 45px;
   font-weight: 700;
  text-align: left;
  color: #fff700;
  &:before{
    content: "";
    width: 49px;
    height: 49px;
   background-color: rgba(0,0,0,0.3);
    position: absolute;
    left: -25px;
    top: 10px;
    transform: rotate(45deg);
    z-index: -1;
  }
}
ul.efficientbox{
  margin:0px;
  padding:0px;
  list-style: none;
  li{
    color: #fff;
    text-align: right;
    font-size: 30px;
    font-weight: 700;
    position: relative;
    height: 45px;
    margin-bottom: 10px;
    text-shadow: 3px 1px 5px #000;
    &:after{
      content: "";
      width: 32px;
      height: 32px;
      position: absolute;
      top: 7px;
      right: -16px;
      transform: rotate(45deg);
      z-index: -1;
    }
    &:nth-child(1){
      background-color: #009f64;
      &:after{
        background-color: #009f64;
      }
    }
    &:nth-child(2){
      background-color: #2caf36;
      &:after{
        background-color: #2caf36;
      }
    }
    &:nth-child(3){
      background-color: #b6d709;
      &:after{
        background-color: #b6d709;
      }
    }
    &:nth-child(4){
      background-color: #fff700;
      &:after{
        background-color: #fff700;
      }
    }
    &:nth-child(5){
      background-color: #febe09;
      &:after{
        background-color: #febe09;
      }
    }
    &:nth-child(6){
      background-color: #f57210;
      &:after{
        background-color: #f57210;
      }
    }
    &:nth-child(7){
      background-color: #dd0128;
      &:after{
        background-color: #dd0128;
      }
    }
    &:nth-child(8){
      background-color: #3101dd;
      &:after{
        background-color: #3101dd;
      }
    }
  }
}
.coeffbox{
  fieldset{
    background-color: inherit;
    border:0px;
    p{
      color: #fff;
    }
  }
  .table tr:nth-child(even){
    background-color: inherit!important;
  }
}
.form-floating > .form-select{
  
}
.fz-12{
  font-size: 12px!important;
}
.towercool .table tr:nth-child(even){
  background-color: inherit!important;
}
.effcfz{
  font-size: 12px;
  font-weight: normal;
}
.cmsnewblock{
  color: #fff;
}
.thrprofile{
  .modal-content{
    background-color: $thm-blue;
    color:#fff;
    border:1px solid $thm-orange;
    .table tr td{
      padding: 7px;
    }
  }
  .modal-header{
    border-bottom: 0px;
    color: $thm-yellow;
    padding:5px 10px;
    h5.modal-title{
      color: $thm-yellow;
      padding-top: 10px;
    }
    .btn-close{
      color: #fff;
      background-color: inherit;
      border:inherit;
      font-size: 24px;
    }
  }
  .form-select{
    background-color: $thm-blue;
    color: #fff;
    padding:2px;
    font-size: 12px;
  }
}
.radiocust{
  label{
    position: relative;
  }
  input[type="radio"]{
    border:1px solid $thm-green;
    width: 20px;
    height: 20px;;
    --webkit-appearance:none;
    --mox-appearance:none;
    appearance:none;
    border-radius: 100%;
    position: relative;
    top: 4px;
    &:checked:before{
      content: '';
      width: 11px;
      height: 11px;
      background-color: $thm-green;
      border:1px solid $thm-green;
      border-radius: 100%;
      position: absolute;
      top: 4px;
      left: 4px;
  }
  }
}
@media only screen and (max-width: 1200px){
.left-side-menu{
  width: 260px;
}
}
@media only screen and (max-width: 1025px){
.left-side-menu{
  display: none;
}
.enlarged .left-side-menu{
  width: 260px!important;
}
.sidebar-enable .left-side-menu{
  display: block;
  transition: all ease .5s;
}
.card.transparent{
  padding-left: 150px;
}
.button-menu-mobile{
  visibility: visible;
  margin-right: 10px;
}
.sdlogo{
  display: block;
}
.sidebar-enable .sdlogo{
  display: none;
}
.card.transparent{
  padding-left: 0px;
}
.enlarged .content-page {
    margin-left: 0px !important;
}
.logo-two {
    position: absolute;
    top: -10px;
    right: 0px;
}
.content-page{
  margin-top: 30px;
}
}
@media only screen and (max-width: 1367px){
  .table-blk{
    white-space: nowrap;
  }
}
@media only screen and (max-width: 1199px){
  .uerpackages{
    .col-lg-4{
      flex:0 0 50%;
      max-width: 50%;
      margin-bottom: 100px;
    }
    .subscriptionTable{
      margin-bottom: 30px;
    }
  }
}
@media only screen and (max-width: 992px){
.left-side-menu {
    background: rgba(0,0,0,0.8);
}
#resptable table, 
#resptable thead, 
#resptable tbody, 
#resptable th, 
#resptable td, 
#resptable tr { 
   display: block; 
 }

#resptable thead tr { 
   position: absolute;
   top: -9999px;
   left: -9999px;
 }

#resptable tr { border: 1px solid #ccc; }

#resptable td { 
   border: none;
   border-bottom: 1px solid #eee; 
   position: relative;
   padding-left: 50%; 
   white-space: normal;
   text-align:left;
 }

#resptable td:before { 
   position: absolute;
   top: 6px;
   left: 6px;
   width: 45%; 
   padding-right: 10px; 
   white-space: nowrap;
   text-align:left;
   font-weight: bold;
 }
#resptable td:before { content: attr(data-title); }
}
@media only screen and (max-width: 768px){
.trspbg{
  width: 100px;
}
.uerpackages{
  .col-lg-4{
    flex:0 0 80%;
    max-width: 80%;
  }
}
}
@media only screen and (max-width: 650px){
  ul.cmsmenu{
    li a{
      padding:10px 10px;
      font-size: 14px;
    }
  }
}
@media only screen and (max-width: 576px){
  .uerpackages{
    .col-lg-4{
      flex:0 0 100%;
      max-width: 100%;
    }
  }
  ul.cmsmenu{
    width: 350px;
    white-space: nowrap;
    overflow-x: scroll;
    li a{
      padding:10px 10px;
      font-size: 14px;
    }
  }
  .storethr{
    .modal-body{
      height: 500px;
      overflow-y: auto;
    }
  }
}
@media only screen and (max-width: 480px){
.form-floating>label{
  font-size: 9px;
  white-space: nowrap;
}

}