.newprojectbox {
  position: relative;
  margin-bottom: 40px;
  transition: all 1s ease;

}

.project-contentbox {
  background-color: #fff;
  box-shadow: -1px 5px 6px 2px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: -1px 5px 6px 2px rgba(0, 0, 0, 0.29);
  padding: 15px;
  width: 100%;
  max-width: 420px;
  position: absolute;
  bottom: -10px;
  left: 10px;
  border-radius: 5px;
  transition: all 1s ease;
  overflow: hidden;

}
.project-contentbox h3 {
  margin: 0px;
  padding: 10px 0px 20px 0px;
  font-size: 18px;
}
.project-contentbox span i {
  background-color: #999;
  color: #fff;
  padding: 10px;
}
.project-contentbox .contentbelow {
  color: #333;
  padding-bottom: 5px;
  /* transition: all 1s ease; */
}
.project-contentbox .contentbelow h6 {
  margin: 0px;
  padding: 0px;
  font-size: 17px;
  color: #a5a5a5;
}

.project-contentbox .contentbelow span.greentxt {
  color: #64a70b;
  font-size: 12px;
}
.project-contentbox .contentbelow p {
  margin: 0px;
  padding: 0px;
  font-size: 13px;
  color:#333!important;
}
.project-contentbox .contentbelow .hide {
  display: none;
}
.newprojectbox .image-container{
  position: relative;
  overflow: hidden;
}

.newprojectbox a:hover .project-contentbox .contentbelow {
  transition: all 1s ease;
  padding-bottom: 10px;
  
}
.newprojectbox a:hover .project-contentbox .contentbelow .hide {
  display: flex;
}
.newprojectbox a:hover .project-contentbox .contentbelow h6 {
  color: #333;
}
.newprojectbox a:hover .project-contentbox span i {
  background-color: #64a70b;
}


.newprojectbox:hover .project-contentbox {
  bottom: 0; /* Change the desired position on hover */
}