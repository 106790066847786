.left-box{
    margin: auto;
    background-image:linear-gradient(to left, rgb(0 12 46), rgb(0 32 109));
    height:80%;
}
.right-box{
    margin: auto;
    background-color: #fff;
    height:80%;
}
.vizcontent{
    padding: 15px;
}


.middleDiv{
    /* width: 400px;
    height: 250px; */
    /* background-color: red; */
    
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    
    margin: auto;
}
.txtWhite{
    color: #fff !important;
}

.colBlue{
    background-color: royalblue;
    /* height: 100px;
    width: 100px; */
    margin:  auto;position: absolute;
    top:1;
    bottom: 0;
    left: 0;
    right: 0;
}
.colGreen{
    background-color: green;
    /* height: 100px;
    width: 100px;  */
    margin: auto;
    position: absolute;
    top:0;
    bottom: 1;
    left: 0;
    right: 0;
}