.scrollable {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.scrollable::-webkit-scrollbar {
  width: 4px;
}

.scrollable::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #888;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.clas-blac{color: #000 !important;}

.visual-group label, label {
  color: #c5c5c5;
}

.desoprbox.orange {
  border: 4x solid #0fd6bc !important;
}

.map-container{
  width: 100%;
  height: 100vh;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.table.table-colorchild tr:nth-child(even){
  background-color:rgb(167 196 230) !important;
   }
.table tr:nth-child(even){
  background-color: rgb(255 255 255)!important;
   }
   .form-floating > .form-control input{background-color: transparent !important;}
   .text-p-color{color: rgb(0, 217, 255) !important;}

.App-link {
  color: #61dafb;
}
.new-td-color tr td{color: #000 !important;}
.new-td-color tr td input{color: #000 !important;background-color: #fff !important;}
.lable-colord{font-size: 14px;color: rgb(0, 217, 255) !important;font-weight: 700 !important;}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-floating.backtra input{background-color: transparent !important;}

.form-floating > .backtra{background-color: transparent !important;}

.border-blue-sky tr{background-color:transparent !important;}

.overlay-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.overlay-spinner2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  justify-content: center;
  align-items: center;
  z-index: 9999;
  text-align: center;

}

.overlay-spinner-in{position: relative;
  top: 40%;
  left: 0;

}

.spinner-border {
  color: #007bff;
}

.modal-dialog-centered::before{
  content:'';
}


.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media print {
section{
  page-break-after: always;
}
}

.iconImage{
  /* filter: invert(100%); */
  /* background-color: #4b9f39; */
    height: 52px;
    margin-left: 8px;
    /* margin-top: 10px; */
}


.nav-tabs .nav-link.active {
  border: 1px solid #04d7e6;
  /* border-bottom: 0px !important; */
  border-bottom: 1px solid #fff!important;
  border-radius: 12px 12px 0px 0px !important;
  background-color: transparent !important;
  color: #0edda6;
}


.nav-tabs .nav-link {
  color: #0edda6
}

.nav-tabs {
  border-bottom: 1px solid #04d7e6;
}

.nav-tabs .nav-item.active {
  border-bottom: none !important;
}

.nav-link .tabone.active{
  border-bottom: 0px !important;
}


.nav-tabs .nav-link {
  border-radius: 12px 12px 0px 0px !important;
  border-top: 1px solid #04d7e6;
  border-right: 1px solid #04d7e6;
  border-left: 1px solid #04d7e6;
}


.packages-table tr {
  border-top: 0px;
  border-bottom: 1px  solid #05c8d5;
}


.packages-table tr:nth-child(even) {
  background-color: inherit  !important;
}




.subcard {
  height: 355px;
  border-radius: 6px;
  border: solid 3px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #90ff00, #cfc300, #fd9700);
  background-origin: border-box;
  background-clip: content-box, border-box;
}


.card-header h4{
  position: relative;
  text-align: center;
}

.card-header h4:before{
  content: '';
  position: absolute;
  width: 80px;
  height: 3px;
  background: #001655;
  bottom: -8px;
  left: 46%;
}

.subcard .card-header,
.subcard .card-body{
  background: #efefef;
}


@font-face {
  font-family: "Poppins-Medium";
  src:url("./fonts/Poppins-Medium.otf") format("opentype"),
}

.Poppins-Medium{
  font-family: "Poppins-Medium";
}

@font-face {
  font-family: "Poppins-Bold";
  src:url("./fonts/Poppins-Bold.otf") format("opentype"),
}

.Poppins-Bold{
  font-family: "Poppins-Bold";
}

@font-face {
  font-family: "DINNextLTPro-Condensed";
  src:url("./fonts/DINNextLTPro-Condensed.otf") format("opentype"),
}

.DINNextLTPro-Condensed{
  font-family: "DINNextLTPro-Condensed";
}

@font-face {
  font-family: "DINNextLTPro-HeavyCondensed";
  src:url("./fonts/DINNextLTPro-HeavyCondensed.otf") format("opentype"),
}

.DINNextLTPro-HeavyCondensed{
  font-family: "DINNextLTPro-HeavyCondensed";
}

@font-face {
  font-family: "DINNextLTPro-BoldCondensed";
  src:url("./fonts/DINNextLTPro-BoldCondensed.otf") format("opentype"),
}

.DINNextLTPro-BoldCondensed{
  font-family: "DINNextLTPro-BoldCondensed";
}


.systemSeer{
  color: #5db619 !important;
  font-weight: bold;
  font-family: 'DINNextLTPro-Condensed';
  font-size: 16px !important;
}

.systemseerval{
  color: #bde31c  !important;
  font-family: 'DINNextLTPro-BoldCondensed';
  font-weight: bold;
  font-size: 30px !important;
}


.password-toggle {
  position: absolute !important;
  right: 10px !important;
  left: 382px !important;
  top: 50% !important;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-toggle i {
  font-size: 18px;
  color: #868686;
}

.wigdet-two-content p{
  color: #fff !important;
  font-size: 10px!important;
  white-space: nowrap;
}

@media only screen and (max-width: 1500px) {
  .systemseerval{
    font-size: 16px!important;
  }
}
.maripgo{    margin: 0 0 0 auto
}



.log-bg{}
.color-font-color tr td{color: #000 !important;}
.h4-font-size{color: rgb(0, 217, 255) !important;
  display: inline-block;}
 
.color-blaked{background-color: #000;}
.log-bg{ background: linear-gradient(90deg, rgb(0, 217, 255) 2%, rgba(36, 206, 38, 0.958420868347339) 77%);
  padding: 5px;
  border-radius: 46px;
}
.textcolo-delop{color: #323e48 !important;}
.textcolo-delo{color: #323e48 !important;font-weight: 700;}
.devlop{padding: 11px 0 0 0;
  border-top: 2px solid #888ea85c;margin-top: 30px;}
  .lablecolod{padding: 0px !important;}


  /* .form-floating select option{
    background: #1aab8e -webkit-linear-gradient(bottom, #1aab8e 0%, #1aab8e 100%) !important;
  } */
  .p-color{color: rgb(16, 16, 16) !important;}
  /* .p-color{color: rgb(0, 217, 255) !important;} */
  .btn-success{background-color: green;
    border-color: #1fe141;}
    .table-bacg-color{background-color: #c6e0ff;}
    .ul.usernewstepsnomenu li a span.circle.completed{background-color: #2ae308 !important;
      color: #fff !important;
      border: 3px solid#fff !important;}
      ul.usernewstepsnomenu li a span.circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: block;
        background-color: #fff;
        border: 3px solid #2ae308;
        color: #d4d4d4;
        text-align: center;
        line-height: 35px;
        font-size: 14px;
        font-weight: 700;
    }
    ul.usernewstepsnomenu li.compstep a {
      color: #2ae308;
  }
  a.checkactive .active {
    border: 4px solid #68b70b !important;
}
.pading-logo{position: absolute;bottom: 20px;right: 30px;}
ul.usernewstepsnomenu li a:hover {
  color: #18df6b !important;
}
.mt-30{margin-top: 40px 0 0 0;}