body {
  background: url("../images/body-bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
}
body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
body::-webkit-scrollbar-thumb {
  background: #888;
}
body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.form-control {
  background-color: #fff !important;
  border: 1px solid #c5cad2 !important;
  height: 35px !important;
}
.form-control:focus {
  background-color: #fff !important;
}


.btn-primary {
  background-color: #ffff00;
  border: 1px solid #ffff00;
  color: #000;
  border-radius: 30px;
  padding-left: 30px;
  padding-right: 30px;
  text-transform: capitalize;
  box-shadow: 5px 2px 3px rgba(0, 0, 0, 0.3);
}
.btn-primary:hover, .btn-primary:focus {
  background-color: #ffcc00;
  border: 1px solid #ffcc00;
  color: #000;
}

.card.transparent {
  background-color: transparent;
  border: 0px;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(2.1rem + 2px);
  line-height: 1.25;
  background-color: #fff !important;
  border: 1px solid #c5cad2 !important;
  border-radius: 7px;
  color: #000;
}
.form-floating .form-color-none input{    background-color: transparent !important;}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  color: #000;
  height: 100%;
  padding: 7px 10px 10px 15px;
  font-size: 12px;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}

.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}

.form-floating > .form-control::placeholder {
  color: transparent;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.6rem;
  padding-bottom: 0.6rem;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.6rem;
  padding-bottom: 0.6rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.6rem;
  padding-bottom: 0.6rem;
}

.form-floating > .form-select {
  border: 1px solid #c5cad2 !important;
}
.form-floating > .form-select:focus-visible, .form-floating > .form-select:focus {
  border-color: inherit !important;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.col-form-label {
  color: #fff;
}

.radio label {
  color: #fff;
}

.tontxt {
  position: absolute;
  top: 7px;
  color: #dcd8d8;
  right: 36px;
}

.form-floating .form-select option {
  color: #333 !important;
  line-height: 20px;
}

.bootstrap-touchspin {
  width: 87px;
}
.bootstrap-touchspin .input-group-btn {
  height: 34px;
  background-color: rgba(151, 140, 140, 0.5) !important;
  color: #fff;
}
.bootstrap-touchspin .input-group-prepend {
  border-radius: 20px 0px 0px 20px;
}
.bootstrap-touchspin .input-group-append {
  border-radius: 0px 20px 20px 0px;
}
.bootstrap-touchspin .form-control {
  height: 34px;
}
.bootstrap-touchspin .btn-primary {
  background-color: #cecfd1 !important ;
  font-size: 16px;
  padding-left: 12px;
  padding-right: 12px;
  line-height: 10px;
  border: 0px !important;
  box-shadow: inherit !important;
  color: #000 !important;
}
.bootstrap-touchspin .btn-primary:hover, .bootstrap-touchspin .btn-primary:focus {
  background-color: #68b90b !important;
}

.form-floating > .form-select {
  padding-left: 21px;
  padding-right: 21px;
  background-position: right 30px !important;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown-item {
  color: #333 !important;
}

.navbar-custom .topnav-menu .nav-link {
  color: #fff !important;
  font-size: 16px;
}

ul.stepsnomenu {
  margin: 0px;
  padding: 20px 0px 0px 0px;
  list-style: none;
  display: flex;
  flex-direction: column;
}
ul.stepsnomenu li {
  text-align: right;
  position: relative;
  padding: 15px 40px 15px 0px;
}
ul.stepsnomenu li a {
  color: #fff;
  font-size: 13px;
}
ul.stepsnomenu li a:hover {
  color: #ffff00;
}
ul.stepsnomenu li a span {
  position: absolute;
  top: 12px;
  right: -20px;
}
ul.stepsnomenu li a span.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  background-color: #fff;
  border: 1px solid #7fd61b;
  color: #d4d4d4;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  font-weight: 400;
}
ul.stepsnomenu li a span.circle:hover, ul.stepsnomenu li a span.circle.active {
  background-color: #ffff00;
  color: #000;
}
ul.stepsnomenu li a span.circle.completed {
  color: #fff;
  background: #22c312;
  border: 1px solid#fff
}
ul.stepsnomenu li.compstep a {
  color: #2ae308;
  font-weight: 700;
}

.form-floating > .form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../images/angle-down.svg") no-repeat;
  background-position: top 10px right 14px !important;
  background-size: 10px;
  font-size: 12px;
}

.form-floating input[type=file] {
  font-size: 12px;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #68b90b;
  border-color: #68b90b;
}

.yellowcol {
  color: #ffff00 !important;
  font-size: 16px;
}

.rdbg {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px 10px 1px 20px;
  border-radius: 30px;
  border: 1px solid #000;
  margin-bottom: 10px;
}

.table td {
  padding: 0.65rem 0.65rem 0.4rem 0.65rem;
}

.table th {
  padding: 0.65rem;
}

.table .table-blk {
  background-color: rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
}

.table thead th, .table td {
  border: 0px !important;
  vertical-align: middle !important;
  color: #000;
}

.bg-success {
  background:#18df6b !important;
  color: #fff;
}

.page-title.fz-16 {
  font-size: 16px !important;
  line-height: 24px;
  padding-bottom: 10px;
}

.page-title a {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px;
}
.page-title a:hover {
  color: #ffff00;
}

.yellowcol {
  color: #fff !important;
  font-size: 16px;
  font-weight: 600;
}

.desoprbox {
  text-align: center;
  color: #fff;
  width: 250px !important;
  height: 90px;
  background-color: rgb(6 24 92);
  vertical-align: middle;
  display: table-cell;
  border: 2px solid transparent;
  border-radius: 20px;
}
.desoprbox p {
  margin: 0px;
  padding: 0px;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
}
.desoprbox i {
  font-size: 30px;
  color: #68b90b;
}

.desoprbox.orange {
  border: 2px solid #ff9600;
}

.desoprbox.red {
  border: 2px solid #f6531f;
}

.desoprbox:hover p {
  color: #ffff00;
}
.desoprbox:hover i {
  color: #ffff00;
}

a.checkactive {
  position: relative;
}

a.checkactive .active {
  border: 2px solid #68b70b;
}
a.checkactive .active:after {
  content: "\e83f";
  font-family: "feather" !important;
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 20px;
  border-radius: 50%;
  background-color: #68b90b;
  position: absolute;
  right: -13px;
  bottom: 35px;
  border: 2px solid transparent;
}

a.checkactive .desoprbox {
  background-color: rgb(6 24 92);
}

.btn-pryellow {
  background: #10a665;
  /* background: linear-gradient(90deg, rgba(0,217,255,1) 2%, rgba(36,206,38,0.958420868347339) 77%); */
  color: #fff;
  font-weight: 700 !important;
  border-radius: 30px;
  font-weight: 500;
  border: none;
  padding: .45rem .9rem;

}
.btn-black {
  background: #555555;
  color: #fff;
  font-weight: 700 !important;
  border-radius: 30px;
  font-weight: 500;
  border: none;
  padding: .45rem .9rem;
}
.prj .btn:hover {
  color: #fff;
}

.trspbg {
  height: calc(2.1rem + 2px);
  line-height: 1.25;
 ;
  border: 1px solid #c5cad2 !important;
  border-radius: 7px;
  color: #fff !important;
}
/* .trspbg:focus {
  background-color: rgba(151, 140, 140, 0.5) !important;
} */

.logo-two {
  float: right;
  margin: 20px 10px;
}
.logo-two img {
  height: 60px;
}

.button-menu-mobile {
  z-index: 99;
  border: 0;
  background-color: inherit;
  color: #fff;
  font-size: 30px;
  visibility: hidden;
}

.sdlogo {
  display: none;
}

.trasparent-box {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 20px;
}
.trasparent-box h3 {
  font-size: 20px;
}
.trasparent-box h4 {
  text-align: center;
}
.trasparent-box h5 {
  font-size: 24px;
  font-weight: 300;
}
.trasparent-box .border-bottom {
  border-bottom: 1px solid rgba(255, 255, 244, 0.2) !important;
}

.yellowcol {
  color: #ffff00 !important;
}
.styblue {
  color: rgb(0, 217, 255) !important;font-weight: 700;font-size: 14px;
}

.resultproject {
  color: #fff;
}
.resultproject h4 {
  color: #ffff00;
}
.resultproject p {
  color: #fff;
  margin-bottom: 0px;
}

ul.cmsmenu {
  margin: 0px;
  padding: 10px 0px 20px 0px;
  list-style: none;
}
ul.cmsmenu li {
  margin: 0px;
  padding: 0px;
  display: inline-block;
  padding-right: 10px;
}
ul.cmsmenu li a {
  color: #06dbd8;
  border: 1px solid #68e308;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px 30px;
}
ul.cmsmenu li a:hover {
  background-color: #06dbd8;
  color: #fff;
  border-color: #06dbd8;
}
ul.cmsmenu li.active a {
  background-color: #06dbd8;
    color: #fff;
    border-color: #06dbd8;
}

.fz-20 {
  font-size: 14px;
}

.dashstats .card-body {
  padding: 1rem;
}

h1.g-grade {
  font-size: 60px;
  line-height: 48px;
}

.gradeA {
  width: 80px;
  height: 54px;
  background-color: #2caf36;
  position: relative;
  font-size: 45px;
  font-weight: 700;
  text-align: left;
  color: #fff700;
}
/* .gradeA:before {
  content: "";
  width: 49px;
  height: 49px;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  left: -25px;
  top: 10px;
  transform: rotate(45deg);
  z-index: -1;
} */


.gradeAplus,
.gradeA,
.gradeB,
.gradeC,
.gradeD,
.gradeE,
.gradeF,
.gradeG {
  width: 40px;
  height: 35px;
  border-radius: 0px 3px 3px 0px;
  position: relative;
  font-size: 32px;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
  margin-left: 15px;
  margin-bottom: 7px;
  padding-bottom: 7px;
  line-height: 35px;
}





.gradeAplus,
.gradeA,
.gradeB,
.gradeC,
.gradeD,
.gradeE,
.gradeF,
.gradeG{position: relative; z-index: 999;}


.gradeG::before,
.gradeF::before,
.gradeE::before,
.gradeD::before,
.gradeC::before,
.gradeB::before,
.gradeA::before,
.gradeAplus::before {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  top: 5px;
  left: -13px;
  transform: rotate(45deg);
  z-index: -1;
}




.gradeG::before  {
  background-color: #ed231b;
}


.gradeF::before {
  background-color: #f87700
}


.gradeE::before {
  background-color: #ffb700;
}


.gradeD::before {
  background-color: #f2d700;
}

.gradeC::before {
  background-color: #b1bc00;
}

.gradeB::before {
  background-color: #4a9d41;

}

.gradeA::before {
  background-color: #028954;
}


.gradeAplus::before {
  background-color: #018345;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}


.gradeAplus {
  background-color: #018345;
}


.gradeA {
  background-color: #028954;
}

.gradeB {
  background-color: #4a9d41;
}

.gradeC {
  background-color: #b1bc00;
}

.gradeD {
  background-color: #f2d700;
}

.gradeE {
  background-color: #ffb700;
}

.gradeF {
  background-color: #f87700
}

.gradeG {
  background-color: #ed231b;
}

/* .verticalLine {
  position: absolute;
  top: 0;
  right: -10px;
  height: 100%;
  width: 2px;
  background-color: #000;
} */

ul.efficientbox {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
ul.efficientbox li {
  color: #000000;
  text-align: right;
  font-size: 30px;
  font-weight: 700;
  position: relative;
  height: 35px;
  line-height: 35px;
  margin-bottom: 7px;
  border-radius: 3px 0px 0px 3px;
  /* text-shadow: 3px 1px 5px #000; */
}
ul.efficientbox li:after {
  content: "";
  width: 23px;
  height: 24px;
  position: absolute;
  top: 6px;
  right: -11px;
  transform: rotate(45deg);
  z-index: -1;
}
ul.efficientbox li:nth-child(1) {
  background-color: #018345;
  Z-INDEX: 999;
    POSITION: relative;
}
ul.efficientbox li:nth-child(1):after {
  background-color: #018345;
}
ul.efficientbox li:nth-child(2) {
  background-color: #028954;
  Z-INDEX: 999;
    POSITION: relative;
}
ul.efficientbox li:nth-child(2):after {
  background-color: #028954 !important;
}
ul.efficientbox li:nth-child(3) {
  background-color: #4a9d41;
  Z-INDEX: 999;
    POSITION: relative;
}
ul.efficientbox li:nth-child(3):after {
  background-color: #4a9d41;
}
ul.efficientbox li:nth-child(4) {
  background-color: #b1bc00;
  Z-INDEX: 999;
    POSITION: relative;
}
ul.efficientbox li:nth-child(4):after {
  background-color: #b1bc00;
}
ul.efficientbox li:nth-child(5) {
  background-color: #f2d700;
  Z-INDEX: 999;
    POSITION: relative;
}
ul.efficientbox li:nth-child(5):after {
  background-color: #f2d700;
}
ul.efficientbox li:nth-child(6) {
  background-color: #ffb700;
  Z-INDEX: 999;
    POSITION: relative;
}
ul.efficientbox li:nth-child(6):after {
  background-color: #ffb700;
}
ul.efficientbox li:nth-child(7) {
  background-color: #f87700;
  Z-INDEX: 999;
    POSITION: relative;
}
ul.efficientbox li:nth-child(7):after {
  background-color: #f87700;
}
ul.efficientbox li:nth-child(8) {
  background-color: #ed231b;
  Z-INDEX: 999;
    POSITION: relative;
}
ul.efficientbox li:nth-child(8):after {
  background-color: #ed231b;
}

/* .systemSeer{
  color: #5db619 !important;
  font-weight: bold;

  font-size: 16px !important;
}

.systemseerval{
  color: #bde31c  !important;
  font-weight: bold;
  font-size: 30px !important;
} */

.coeffbox fieldset {
  background-color: inherit;
  border: 0px;
}
.coeffbox fieldset p {
  color: #fff;
}
.coeffbox .table tr:nth-child(even) {
  background-color: inherit !important;
}

.fz-12 {
  font-size: 12px !important;
}

.towercool .table tr:nth-child(even) {
  background-color: inherit !important;
}

.effcfz {
  font-size: 12px;
  font-weight: normal;
}

.cmsnewblock {
  color: #fff;
}

.thrprofile .modal-content {
  background-color: #001655;
  color: #fff;
  border: 1px solid #ffa300;
}
.thrprofile .modal-content .table tr td {
  padding: 7px;
}
.thrprofile .modal-header {
  border-bottom: 0px;
  color: #ffff00;
  padding: 5px 10px;
}
.thrprofile .modal-header h5.modal-title {
  color: #ffff00;
  padding-top: 10px;
}
.thrprofile .modal-header .btn-close {
  color: #fff;
  background-color: inherit;
  border: inherit;
  font-size: 24px;
}
.thrprofile .form-select {
  background-color: #001655;
  color: #fff;
  padding: 2px;
  font-size: 12px;
}

.radiocust label {
  position: relative;
}
.radiocust input[type=radio] {
  border: 1px solid #000;
  width: 20px;
  height: 20px;
  --webkit-appearance:none;
  --mox-appearance:none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 100%;
  position: relative;
  top: 4px;
}
.radiocust input[type=radio]:checked:before {
  content: "";
  width: 11px;
  height: 11px;
  background-color: #68b90b;
  border: 1px solid #68b90b;
  border-radius: 100%;
  position: absolute;
  top: 4px;
  left: 4px;
}

@media only screen and (max-width: 1500px) {
  /* body{
    border:5px solid red;
  } */
  .exportpdf{
    position: relative;
    right:20px;
  }
  .card.widget-box-two p{
    font-size: 8px!important;
  }
  .widget-box-two .px-3{
    padding-left: 1rem!important;
  }
}
@media only screen and (max-width: 1367px) {
  .table-blk {
    white-space: nowrap;
  }
}

@media only screen and (max-width: 1300px) {
  /* body{
    border:5px solid blue;
  } */
  .exportpdf{
    position: relative;
    right:40px;
  }
  .card.widget-box-two p{
    font-size: 6px!important;
  }
  .resultproject p{
    font-size: 13px!important;
  }
}
@media only screen and (max-width: 1199px) {
  .uerpackages .col-lg-4 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 100px;
  }
  .uerpackages .subscriptionTable {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 1200px) {
  .left-side-menu {
    width: 260px;
  }
  .exportpdf{
    right:50px;
  }
}
@media only screen and (max-width: 1025px) {
  .left-side-menu {
    display: none;
  }
  .enlarged .left-side-menu {
    width: 260px !important;
  }
  .sidebar-enable .left-side-menu {
    display: block;
    transition: all ease 0.5s;
  }
  .card.transparent {
    padding-left: 150px;
  }
  .button-menu-mobile {
    visibility: visible;
    margin-right: 10px;
  }
  .sdlogo {
    display: block;
  }
  .sidebar-enable .sdlogo {
    display: none;
  }
  .card.transparent {
    padding-left: 0px;
  }
  .enlarged .content-page {
    margin-left: 0px !important;
  }
  .logo-two {
    position: absolute;
    top: -10px;
    right: 0px;
  }
  .content-page {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 992px) {
  .left-side-menu {
    background: rgba(0, 0, 0, 0.8);
  }
  #resptable table,
  #resptable thead,
  #resptable tbody,
  #resptable th,
  #resptable td,
  #resptable tr {
    display: block;
  }
  #resptable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #resptable tr {
    border: 1px solid #ccc;
  }
  #resptable td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
  }
  #resptable td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }
  #resptable td:before {
    content: attr(data-title);
  }
  .gradeAplus, .gradeA, .gradeB, .gradeC, .gradeD, .gradeE, .gradeF, .gradeG{
    position: relative!important;
    right:-22px;
  }
}
@media only screen and (max-width: 768px) {
  .trspbg {
    width: 100px;
  }
  .uerpackages .col-lg-4 {
    flex: 0 0 80%;
    max-width: 80%;
  }
}
@media only screen and (max-width: 650px) {
  ul.cmsmenu li a {
    padding: 10px 10px;
    font-size: 14px;
  }
}
@media only screen and (max-width: 576px) {
  .uerpackages .col-lg-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  ul.cmsmenu {
    width: 350px;
    white-space: nowrap;
    overflow-x: scroll;
  }
  ul.cmsmenu li a {
    padding: 10px 10px;
    font-size: 14px;
  }
  .storethr .modal-body {
    height: 500px;
    overflow-y: auto;
  }
}
@media only screen and (max-width: 480px) {
  .form-floating > label {
    font-size: 9px;
    white-space: nowrap;
  }
}/*# sourceMappingURL=dashboardnewstyles.css.map */
.colyellow-chiller{color: #2ae308 !important;
  font-weight: 700;}
  .chiler-color{font-size: 19px;color: #000;margin: 0 5px 0 0;}
  .lablecolod{font-weight: 700;color: #00d9ff;font-size: 14px;}
  .inputclass-name-pd-0{padding: 0px !important;}
  .bg-success-border-green{border-bottom:1px solid #2ae308 !important ;}
  .head-color-table th{color: #000 !important;font-weight: 600;}
  .border-blue-sky tr{border-bottom:1px solid #00d9ff !important ;}
  .border-blue-sky tr td.middleclas{border-left:1px solid #00d9ff !important ; border-right:1px solid #00d9ff !important ;}
  .border-blue-sky tr td{color: #000;}
  .form-floating input{    background-color: transparent !important;}
  .table-form-input input {
    text-align: center;
  }