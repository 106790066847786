html { min-height: 100% }
body {
    color: #888ea8;
    height: 100%;
    font-size: 0.875rem;
    background: #ffffff;
    overflow-x: hidden;
    overflow-y: auto;
    letter-spacing: 0rem;
    font-family: 'Outfit', sans-serif;
}
h1, h2, h3, h4, h5, h6 { color: #000000; }
:focus { outline: none; }
::-moz-selection { 
  color: #2f44b2;
  background: transparent;
}
::selection {
  color: #2f44b2;
  background: transparent;
}
p { margin-top: 0; margin-bottom: 0.625rem; color: #515365 }
hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid #f1f2f3;
}
strong { font-weight: 600; }
.light { font-weight: 500; }
.strong { font-weight: 600 !important; }
.stronger { font-weight: 700 !important; }
code { color: #2f44b2; }
/* Global */
.object-cover{
    object-fit: cover;
}
.pointer {
    cursor: pointer;
}
.display-none{
    display: none;
}
.marked-read {
    opacity: 0.6;
}
.align-center{
    align-items: center;
}
.avatar-xxs {
    height: 1.1rem;
    width: 1.1rem;
}
.avatar-xs {
    height: 1.9rem;
    width: 1.9rem;
}
.avatar-sm {
    height: 2.25rem;
    width: 2.25rem;
}
.avatar-md {
    height: 3.25rem;
    width: 3.25rem;
}
.avatar-lg {
    height: 4.00rem;
    width: 4.00rem;
}
.avatar-xl {
    height: 5.25rem;
    width: 5.25rem;
}
.fixed-width-xs {
    width: 60px;
}
.fixed-height-xs {
    height: 60px;
}
.width-xs {
    min-width: 80px;
}
.width-sm {
    min-width: 100px;
}
.width-md {
    min-width: 120px;
}
.width-lg {
    min-width: 140px;
}
.width-xl {
    min-width: 160px;
}
.font-10 {
    font-size: 10px;
}
.font-11 {
    font-size: 11px !important;
}
.font-12 {
    font-size: 12px;
}
.font-13{
    font-size: 13px;
}
.font-14{
    font-size: 14px;
}
.font-15 {
    font-size: 15px;
}
.font-17 {
    font-size: 17px;
}
.font-19 {
    font-size: 19px;
}
.font-20 {
    font-size: 20px;
}
.font-25 {
    font-size: 25px;
}
.font-30 {
    font-size: 30px;
}
.font-35 {
    font-size: 35px;
}
.font-45 {
    font-size: 45px;
}
.font-65 {
    font-size: 65px;
}
.font-135 {
    font-size: 135px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-40 {
    margin-top: 40px;
}
.ml-m-12 {
    margin-left: -12px;
}
.p-30{
    padding: 30px;
}
/*Page title*/
.page-header {
    border: 0;
    margin: 0;
}
.page-header:before,
.page-header:after {
    display: table;
    content: "";
    line-height: 0
}
.page-header:after { clear: both }
.page-title h3 {
    margin: 0;
    margin-bottom: 0;
    font-size: 15px;
    color: #3b3f5c;
    font-weight: 700;
}
.page-title span {
    display: block;
    font-size: 11px;
    color: #888;
    font-weight: normal
}
.main-container {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 0 0 0 0px;
}
.main-container::before {
    content: "";
    height: 235px;
    width: 100%;
    /* position: absolute;  */
    /* Use absolute to scroll the blue area */
    position: fixed; 
    /* Use fixed to fix the blue area */
    background-color: #677ada;
}
#container.fixed-header { margin-top: 56px; }
#content {
    position: relative;
    width: 50%;
    flex-grow: 8;
    margin-top: 63px;
    margin-bottom: 0;
    margin-left: 300px;
    transition: .600s;
}
.sub-sidebar-open #content {
    margin-left: 300px;
    transition: .600s;
}
.main-container-fluid > .main-content > .container {
    float: left;
    width: 100%
}
#content > .wrapper {
    -webkit-transition: margin ease-in-out .1s;
    -moz-transition: margin ease-in-out .1s;
    -o-transition: margin ease-in-out .1s;
    transition: margin ease-in-out .1s;
    position: relative
}
.widget {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 6px;
    -webkit-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
}
.layout-top-spacing { margin-top: 20px; }
.layout-spacing { padding-bottom: 40px; }
.layout-px-spacing { padding: 0 20px 0 18px!important; min-height: calc(100vh - 170px)!important; }
.sidebar-closed .layout-px-spacing { padding: 0 16px!important;}
.widget.box .widget-header {
    background: #fff;
    padding: 0px 8px 0px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}
.row [class*="col-"] .widget .widget-header h4 {
    color: #3b3f5c;
    font-size: 17px;
    font-weight: 600;
    margin: 0;
    padding: 16px 15px;
}
.seperator-header {
    background: transparent;
    box-shadow: none;
    margin-bottom: 40px;
    border-radius: 0;
}
.seperator-header h4 {
    color: #888ea8;
    margin-bottom: 0;
    display: inline-block;
    border: 2px dashed #888ea8;
    line-height: 1.4;
    padding: 3px 6px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 4px;
    letter-spacing: 1px;
}
.widget .widget-header {
    border-bottom: 0px solid #f1f2f3;
}
.widget .widget-header:before,
.widget .widget-header:after {
    display: table;
    content: "";
    line-height: 0
}
.widget .widget-header:after { clear: both }
.widget-content-area {
    padding: 20px;
    position: relative;
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
.content-area {
    max-width: 58.333333%;
    margin-left: 80px;
}
.widget.box .widget-footer {
    padding: 1rem 1rem;
    background-color: #ffffff;
    border-top: 1px solid #EBEDF3;
}
/* 
=====================
    Navigation Bar
=====================
*/
.header-container {
    z-index: 1000;
    border-bottom: 0px solid #f5f5f5;
}
.header-container .navbar {
    padding: 4px 0;
    height: 55px;
    background-color: transparent;
}
.navbar {
    padding: 0;
    background: #ffffff;
}
.navbar .theme-brand {
    padding: 0.9px 0 0.9px 17px!important;
    border-radius: 6px 6px 0 0;
    justify-content: space-between;
}
.navbar .theme-brand li.theme-logo { align-self: center; }
.navbar .theme-brand li.theme-logo img {
    width: 34px;
    height: 34px;
}
.navbar .theme-brand li.theme-text {
    margin-right: 47px;
}
.navbar .theme-brand li.theme-text a {
    font-size: 24px !important;
    color: #2f44b2 !important;
    line-height: 2.75rem;
    padding: 0 0.8rem;
    text-transform: initial;
    position: unset;
    font-weight: 700;
}
.navbar.expand-header { margin: 0 }
.navbar-brand {
    width: 5.5rem;
    padding-top: 0.0rem;
    padding-bottom: 0.0rem;
    margin-right: 0.0rem;
}
.navbar-expand-sm .navbar-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.navbar-expand-sm .navbar-item .nav-link {
    line-height: 2.75rem;
    padding: 6.24px 9.6px;
    text-transform: initial;
    position: unset;
}
.header-container.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0px;
    padding-left: 298px;
    transition: all ease .600s;
    background-color: transparent;
    background-color: #677ada;
}
.sub-sidebar-open .header-container.fixed-top {
    left: 0px;
    transition: all ease .600s;
}
.header-container .navbar .dropdown-menu {
    border-radius: 6px;
    border-color: #e0e6ed;
}
.header-container .navbar .dropdown-item {
    line-height: 1.8;
    font-size: 0.96rem;
    padding: 15px 0 15px 0;
    word-wrap: normal;
    border-radius: 6px;
}
.header-container .navbar .navbar-item .nav-item .dropdown-item.active,
.header-container .navbar .navbar-item .nav-item .dropdown-item:active {
    background-color: transparent;
    color: #16181b;
}
.header-container .navbar .navbar-item .nav-item.dropdown .nav-link:hover span {
    color: #e0e6ed!important;
}
.header-container .navbar .navbar-item .nav-item.dropdown .dropdown-menu {
    top: 109%;
    border-radius: 0;
    border: none;
    border-radius: 6px;
    -webkit-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
    background: #fff;
    margin-top: 0;
}
.header-container .navbar .navbar-item .nav-item.dropdown .dropdown-menu:after {
    position: absolute;
    content: '';
    top: -10px;
    margin-left: -7px;
    height: 0;
    width: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 15px solid #fff;
    left: auto;
    right: 8px;
    border-bottom-color: #ffffff;
}
.header-container .navbar .megamenu-dropdown .dropdown-menu.megamenu {
    min-width:700px;
    padding: 1.2rem;
    left: -290px;
}
.header-container .navbar .megamenu-dropdown .dropdown-menu.megamenu a {
   color: #666;;
}
.header-container .navbar .megamenu-dropdown .dropdown-menu.megamenu a:hover {
    color: #2f44b2;
 }
.header-container .navbar .megamenu-dropdown .dropdown-menu.megamenu.show {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.header-container .navbar .megamenu-dropdown .dropdown-menu.megamenu .no-gutters .dropdown-icon-item {
    display: block;
    text-align: center;
    font-size: 15px;
    padding: 10px;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    width: 80px;
    margin: 5px 0px;
    transition: all ease 0.3s;
}
.header-container .navbar .megamenu-dropdown .dropdown-menu.megamenu .no-gutters .dropdown-icon-item:hover {
    background: #f9f9f9;
    transition: all ease 0.3s;
}
.header-container .navbar .megamenu-dropdown .dropdown-menu.megamenu .no-gutters .dropdown-icon-item img{
    display: block;
    height: 40px;
    width: 40px;
    margin: 0px auto 7px;
}
.header-container .navbar .megamenu-dropdown .dropdown-menu.megamenu .no-gutters .dropdown-icon-item span {
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #777;
    font-weight: 400;
}
.header-container .navbar .megamenu-dropdown .dropdown-menu:after {
    top: -8.5px;
    left: 49%!important;
    right: auto!important;
}
/*   Language   */
.language-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
}
.header-container .navbar .language-dropdown {
    margin-left: 10px;
}
.navbar .navbar-item .nav-item.dropdown.language-dropdown .nav-link i { color: #ffffff; font-size: 22px; }
.header-container .navbar .language-dropdown a.nav-link {
    position: relative;
    padding: 8px 8px;
    display: flex;
    background: transparent;
    border-radius: 8px;
    transition: all ease 0.6s;
}
.header-container .navbar .language-dropdown a.nav-link:hover {
    background: #2f44b2;
    transition: all ease 0.6s;
}
.header-container .navbar .language-dropdown a.dropdown-toggle:after {
    display: none;
}
.header-container .navbar .language-dropdown a.dropdown-toggle img {
    width: 20px;
    height: 20px;
    margin: 11px 0px;
}
/*   Language Dropdown  */
.navbar .navbar-item .nav-item .dropdown-menu {
    z-index: 9999;
    min-width: 13rem;
    padding: 10px;
    top: 68px;
    left: -184px;
}
.header-container .navbar .language-dropdown .dropdown-menu {
    min-width: 7rem;
    left: 50%;
    transform: translate(-50%, 0);
    box-shadow: none;
    top: 105%!important;
    z-index: 1070;
    border: 1px solid #e0e6ed;
    padding: 6px 6px;
    border-radius: 4px;
}
.header-container .navbar .language-dropdown .dropdown-menu:before {
    top: -10px;
    left: 41%;
    right: auto!important;
}
.header-container .navbar .language-dropdown .dropdown-menu:after {
    top: -8.5px;
    left: 49%!important;
    right: auto!important;
}
.header-container .navbar .language-dropdown .dropdown-menu .dropdown-item:hover {
    background: #f7f9ff;
    border-radius: 6px;
}
.header-container .navbar .language-dropdown .dropdown-menu .dropdown-item.active, .header-container .navbar .language-dropdown .dropdown-menu .dropdown-item:active {
    background: transparent;
    color: #16181b;
}
.header-container .navbar .language-dropdown .dropdown-menu a img {
    width: 20px;
    height: 20px;
    margin-right: 16px;
}
.header-container .navbar .language-dropdown .dropdown-menu a span {
    color: #3b3f5c;
    font-weight: 500;
}
/*Message & Fullscreen Dropdown*/
.message-dropdown, .fullscreen-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown, 
.navbar .navbar-item .nav-item.dropdown.fullscreen-dropdown { margin-left: 10px; }
.navbar .navbar-item .nav-item.dropdown.message-dropdown .nav-link, 
.navbar .navbar-item .nav-item.dropdown.fullscreen-dropdown .nav-link {
    padding: 8px 8px;
    display: flex;
    background: transparent;
    border-radius: 8px;
    transition: all ease 0.6s;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .nav-link:hover, 
.navbar .navbar-item .nav-item.dropdown.fullscreen-dropdown .nav-link:hover {
    background: #4a5ec5;
    transition: all ease 0.6s;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .nav-link:after, 
.navbar .navbar-item .nav-item.dropdown.fullscreen-dropdown .nav-link:after { display: none; }
.navbar .navbar-item .nav-item.dropdown.message-dropdown .nav-link i,
.navbar .navbar-item .nav-item.dropdown.fullscreen-dropdown .nav-link i { color: #ffffff; font-size: 22px; }
.navbar .navbar-item .nav-item.dropdown.message-dropdown .nav-link span.badge,
.navbar .navbar-item .nav-item.dropdown.fullscreen-dropdown .nav-link span.badge {
    position: absolute;
    top: 13px;
    right: 2px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    padding: 1px 5px 0px;
    font-size: 10px;
    color: #fff!important;
    background: #1b55e2;
    border: 1px solid #e0e6ed;
    top: 10px;
    right: -8px;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown.double-digit .nav-link span.badge {
    top: 11px;
    right: 1px;
    width: 22px;
    height: 22px;
    padding: 3px 3px 0px;
    font-size: 9px;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu {
    min-width: 15rem;
    right: -15px;
    left: auto;
    padding: 10px!important;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .dropdown-item {
    padding: 13px 21px;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .dropdown-item.active,
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .dropdown-item:active {
    background-color: transparent;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .dropdown-item:focus,
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: rgba(27, 85, 226, 0.1803921568627451);
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .dropdown-item:first-child {
    padding-top: 16px;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu:after {
    right: 24px;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .dropdown-item:last-child {
    padding-bottom: 16px;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .media { margin: 0; }
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .media img {
    width: 40px;
    height: 40px;
    margin-right: 11px;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .media .media-body h5.usr-name {
    font-size: 15px;
    margin-bottom: 0px;
    color: #1b55e2;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .media .media-body {
    align-self: center;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .media .media-body p.msg-title {
    font-size: 9px;
    font-weight: 700;
    color: #3b3f5c;
    margin-bottom: 0;
}
/*Notification Dropdown*/
.notification-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
}
.navbar .navbar-item .nav-item.dropdown.notification-dropdown { margin-left: 10px; }
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link {
    padding: 8px 8px;
    display: flex;
    background: transparent;
    border-radius: 8px;
    transition: all ease 0.6s;
}
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link:hover {
    background: #2f44b2;
    transition: all ease 0.6s;
}
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link:after { display: none; }
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link i { color: #ffffff; font-size: 22px; }
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link span.badge {
    position: absolute;
    top: 9px;
    right: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    padding: 3px 0px 0px;
    font-size: 10px;
    color: #fff!important;
    background: #c2d5ff;
    display: block;
    border: 1px solid #3b3f5c;
}
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu {
    min-width: 15rem;
    right: -5px;
    left: auto;
}
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .dropdown-item {
    padding: 0.625rem 1rem;
}
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .dropdown-item:focus,
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: rgba(27, 85, 226, 0.1803921568627451);
}
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .dropdown-item:not(:last-child) {
    border-bottom: 1px solid #ebedf2;
}
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .media { margin: 0; }
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu svg {
    width: 20px;
    height: 20px;
    font-weight: 600;
    color: #1b55e2;
    fill: rgba(0, 23, 55, 0.08);
    margin-right: 14px;
    align-self: center;
}
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .media-body .notification-para {
    white-space: normal;
    line-height: 16px;
    color: #3b3f5c;
}
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .media-body .notification-para .user-name {
    font-weight: 600;
    color: #1b55e2;
    display: inline-block;
}
.navbar .navbar-item .nav-drop.is-notification-dropdown {
    width: 250px;
    right: 0 !important;
    left: unset !important;
    max-height: unset !important;
}
.navbar .notification-dropdown .nav-link .blink {
    position: absolute;
    top: 0px;
    right: 2px;
}
.navbar .notification-dropdown .nav-link .blink .circle {
    background: #ffffff;
    width: 7px;
    height: 7px;
    border-radius: 50%;
}
.navbar .notification-dropdown .nav-link .blink .circle::before {
    background-color: #ffffff;
    content: "";
    display: table;
    border-radius: 50%;
    position: absolute;
}
.pulse, .circle::before {
    animation-name: pulse;
    animation-duration: .9s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out; 
}
@keyframes pulse {
    0% {
      opacity: 1;
      width: 7px;
      height: 7px;
      left: 0;
      top: 0; }
    95% {
      opacity: 0.1;
      left: -10.5px;
      top: -10.5px;
      width: 28px;
      height: 28px; }
    100% {
      opacity: 0;
      width: 7px;
      height: 7px;
      left: 0;
      top: 0; } 
}
/* Search */
.navbar .navbar-item .nav-item.search-animated {
    position: relative;
}
.navbar .navbar-item .nav-item.search-animated i {
    font-weight: 600;
    margin: 0 12px;
    cursor: pointer;
    color: #ffffff;
    position: absolute;
    font-size: 18px;
    top: 9px;
}
.navbar .navbar-item .nav-item form.form-inline input.search-form-control {
    width: 100%;
    font-size: 14px;
    background-color: #4a5ec5;
    border: none;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    color: #ffffff;
    padding: 0px 4px 0px 40px;
    height: 36px;
    font-weight: 400;
}
.navbar .navbar-item .nav-item form.form-inline input.search-form-control:focus {
    border: 1px solid #4a5ec5;
    box-shadow: none;
}
.navbar .navbar-item .nav-item .form-inline.search .search-form-control {
    border: 1px solid #4a5ec5;
    width: 100%;
    width: 350px;
}
.navbar .navbar-item .nav-item form.form-inline input.search-form-control::-webkit-input-placeholder {
    color: #ffffff;
    letter-spacing: 0px;
    font-size: 13px;
}
.navbar .navbar-item .nav-item form.form-inline input.search-form-control::-ms-input-placeholder {
    color: #ffffff;
    letter-spacing: 0px;
    font-size: 13px;
}
.navbar .navbar-item .nav-item form.form-inline input.search-form-control::-moz-placeholder {
    color: #ffffff;
    letter-spacing: 0px;
    font-size: 13px;
}
.navbar .navbar-item .nav-item form.form-inline input.search-form-control:focus::-webkit-input-placeholder {
    color: #ffffff;
}
.navbar .navbar-item .nav-item form.form-inline input.search-form-control:focus::-ms-input-placeholder {
    color: #ffffff;
}
.navbar .navbar-item .nav-item form.form-inline input.search-form-control:focus::-moz-placeholder {
    color: #ffffff;
}
.search-overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #000000 !important;
    z-index: 814!important;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    top: 55px;
}
.search-overlay.show {
    display: block;
    opacity: .6;
}
/* User Profile Dropdown*/
.navbar .navbar-item .nav-item .nav-link.user {
    padding: 0 20px 0 16px;
    position: relative;
}
.navbar .navbar-item .nav-item.dropdown .nav-link:after {
    display: none;
}
.navbar .navbar-item .nav-item .nav-link img {
    width: 28px;
    border-radius: 50%;
    height: 28px;
}
.navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu {
    min-width: 15rem;
    left: -194px;
    padding: 10px!important;
}
.navbar .navbar-item .nav-item .dropdown-menu .dropdown-item a {
    display: block;
    color: #3b3f5c;
    font-size: 13px;
    font-weight: 600;
    padding: 9px 30px;
}
.navbar .navbar-item .nav-drop.is-account-dropdown {
    width: 210px;
    right: 0 !important;
    left: unset !important;
    max-height: unset !important;
}
.navbar .navbar-item a.account-item {
    cursor: pointer;
}
.navbar .navbar-item a.account-item:hover {
    background: #f7f9ff;
    border-radius: 6px;
}
.navbar .navbar-item .nav-drop .inner {
    position: relative;
}
.navbar .navbar-item .nav-drop .inner .nav-drop-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px 16px 10px;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
    background: #fff;
}
.navbar .navbar-item .nav-drop .inner .nav-drop-header span.username, .navbar .navbar-item .nav-drop .inner .nav-drop-header a.username {
    font-weight: 600 !important;
}
.navbar .navbar-item .nav-drop .inner .nav-drop-header a {
    height: unset;
    width: unset;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
}
.navbar .navbar-item.is-account img {
    height: 38px;
    width: 38px;
    max-height: 38px;
    border-radius: 50%;
    object-fit: cover;
}
.navbar .navbar-item .nav-drop .inner .nav-drop-body.account-items {
    padding: 10px 0;
}
.navbar .navbar-item .nav-drop .inner .nav-drop-body {
    max-height: 445px;
    padding: 0 10px;
    overflow-y: auto;
}
.navbar .navbar-item .nav-drop .inner .nav-drop-body.account-items .account-item {
    padding: 10px 16px;
    display: block;
    line-height: 1.2;
}
.navbar .navbar-item .nav-drop .inner .nav-drop-body.account-items hr {
    margin: .4rem 0 !important;
}
.navbar .navbar-item hr {
    background-color: #f5f5f5;
    border: none;
    display: block;
    height: 2px;
    margin: 1.5rem 0;
}
/* Right Setting Buutton */
.navbar .navbar-item .nav-item .nav-link.rightbarCollapse {
    padding: 0 20px 0 16px;
    position: relative;
    line-height: 1;
}
.navbar .navbar-item .nav-item .nav-link.rightbarCollapse i {
    color: #ffffff;
    font-size: 22px;
}
/* Sub Header */
.sub-header-container {
    padding: 0 20px 0 18px!important;
    z-index: 1029;
}
.sub-header-container .navbar {
    background: transparent;
    border-radius: 0;
    padding: 9px 0;
    justify-content: flex-start;
    min-height: 53px;
    width: 100%;
    border-bottom: 1px solid rgb(255 255 255 / 10%);
}
.sub-header-container .navbar .toggle-sidebar,
.sub-header-container .navbar .sidebarCollapse {
    position: relative;
    padding: 0 25px 0 0px;
    margin-left: 0;
}
.sub-header-container .navbar .sidebarCollapse i {
    font-size: 20px;
    color: #ffffff;
    vertical-align: middle;
}
.page-header {
    display: flex;
    padding: 0 0;
}
.sub-header-container .breadcrumb-one { 
    display: inline-block;
}
.sub-header-container .breadcrumb-one .breadcrumb {
    padding: 0;
    vertical-align: sub;
    background-color: transparent;
    margin-bottom: 0;
}
.sub-header-container .breadcrumb-one .breadcrumb-item { align-self: center; }
.sub-header-container .breadcrumb-one .breadcrumb-item a {
    color: #ffffff;
}
.sub-header-container .breadcrumb-one .breadcrumb-item a svg {
    width: 18px;
    height: 18px;
    vertical-align: sub;
    fill: #ffffff;
}
.sub-header-container .breadcrumb-one .breadcrumb-item.active a { color: #515365; }
.sub-header-container .breadcrumb-one .breadcrumb-item.active {
    color: #ffffff;
    font-weight: 600;
}
.sub-header-container .breadcrumb-one .breadcrumb-item+.breadcrumb-item { padding: 0px; }
.sub-header-container .breadcrumb-one .breadcrumb-item+.breadcrumb-item::before {
    color: #ffffff;
    padding: 0 6px 0 6px;
    vertical-align: bottom;
}
@media(max-width: 575px) {
    .page-header {
        display: block;
    }
    .sidebarCollapse {
        padding-left: 0px !important;
    }
    /* Right Side Filter */
    .right-side-filter {
        flex-direction: row;
        width: 100%;
        justify-content: flex-end;
        margin-top: 20px;
    }
    .right-side-filter .nav-item.more-dropdown .flatpickr-input {
        height: 31px;
        font-size: 11px;
        width: 100%;
        padding-right: 0px;
    }
    .right-side-filter li:first-child {
        flex-grow: 1;
    }
}
.sub-header-container .more-dropdown .custom-dropdown-icon { padding-right: 20px; }
.sub-header-container .more-dropdown .custom-dropdown-icon a.dropdown-toggle {
    position: relative;
    padding: 9px 35px 10px 15px;
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    transform: none;
    font-size: 13px;
    line-height: 17px;
    background-color: #fff;
    letter-spacing: normal;
    min-width: 115px;
    text-align: inherit;
    color: #1b2e4b;
    box-shadow: none;
    max-height: 35px;
}
.sub-header-container .more-dropdown .custom-dropdown-icon a.dropdown-toggle svg {
    position: absolute;
    right: 15px;
    top: 11px;
    color: #888ea8;
    width: 13px;
    height: 13px;
    margin: 0;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
}
.sub-header-container .more-dropdown .custom-dropdown-icon.show a.dropdown-toggle svg {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.sub-header-container .more-dropdown .custom-dropdown-icon .dropdown-menu {
    position: absolute;
    right: 16px;
    top: 40px!important;
    padding: 8px 0;
    border: none;
    min-width: 155px;
    border: 1px solid #d3d3d3;
}
.sub-header-container .more-dropdown .custom-dropdown-icon .dropdown-menu a {
    padding: 8px 15px;
    font-size: 13px;
    font-weight: 500;
    color: #3b3f5c;
}
.sub-header-container .more-dropdown .custom-dropdown-icon .dropdown-menu a:hover {
    background-color: #bae7ff;
    color: #2196f3;
}
/* Sidebar */
.tl-logo-area {
    height: 65px;
    width: 110px;
    background: #808fda;
    z-index: 1029;
    position: fixed;
    top: 0;
    left: -10px;
    display: flex;
    border-bottom-right-radius: 40%;
    align-items: center;
    justify-content: center;
}
.logo-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
}
.logo-area .navbar-logo {
    height: 25px;
    width: 25px;
}
.navbar .navbar-item .nav-item .nav-link img.navbar-logo{
    height: 25px;
    width: 25px;
    border-radius: 0%;
}
.logo-area .nav-link {
    font-size: 17px !important;
    color: #ffffff !important;
    line-height: 2.75rem;
    padding: 0 0.2rem;
    text-transform: initial;
    position: unset;
    font-weight: 700;
}
.sidebar-wrapper {
    width: 280px;
    position: fixed;
    z-index: 1028;
    transition: .600s;
    /* height: 100vh; */
    touch-action: none;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    left: 18px;
    top: 0px;
    bottom: 0px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: none;
}
.sidebar-theme { 
    background: transparent; 
}
.fixed-profile {
    text-align: center;
    padding: 30px 10px 0px;
    margin-bottom: 0px;
    height: 235px;
}
.fixed-profile img.profile-avatar {
    height: 70px;
    width: 70px;
    object-fit: cover;
    border-radius: 50%;
}
.fixed-profile .profile-option-container {
    display: flex;
    padding-left: 0;
    padding-right: 0px;
    margin-bottom: 0;
    list-style: none;
    margin: 22px 0px 0px;
    align-items: center;
    justify-content: space-between;
}
.fixed-profile .profile-option-container .option-item {
    margin: 0px 0px;
    width: 50%;
    justify-content: flex-start;
}
.fixed-profile .profile-option-container .option-item:nth-child(2) {
    justify-content: flex-end;
}
.fixed-profile .profile-option-container .option-link-container {
    cursor: pointer;
    display: flex;
    align-items: center;
}
.fixed-profile .profile-option-container .option-link-container h6 {
    color: #ffffff;
    margin: 0px 0px 2px;
    font-size: 13px;
}
.fixed-profile .profile-option-container .option-link-container p {
    color: #bfcaff;
    margin: 0px;
    font-size: 12px;
}
.fixed-profile .profile-option-container .option-link {
    padding: 5px 6px;
    display: flex;
    background: #7a8ce6;
    border-radius: 8px;
    transition: all ease 0.4s;
    margin-right: 10px;
    position: relative;
}
.fixed-profile .profile-option-container .option-link-container:hover .option-link {
    background: #4a5ec5;
    transition: all ease 0.4s;
}
.fixed-profile .profile-option-container .option-link i {
    color: #ffffff;
    font-size: 22px;
} 
.fixed-profile .profile-option-container .option-link .blink {
    position: absolute;
    top: 0px;
    right: 2px;
}
.fixed-profile .profile-option-container .option-link .blink .circle {
    background: #ffffff;
    width: 7px;
    height: 7px;
    border-radius: 50%;
}
.fixed-profile .profile-option-container .option-link .blink .circle::before {
    background-color: #ffffff;
    content: "";
    display: table;
    border-radius: 50%;
    position: absolute;
}
.premium-border {
    --border-width: 3px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    font-family: Lato, sans-serif;
    font-size: 2.5rem;
    text-transform: uppercase;
    color: white;
    background: #050b29;
    border-radius: 50%;
    margin: 0 auto;
    z-index: 1;
}
.premium-border::after {
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(60deg, #4486e9, #bb8dec, #f25fd0, #f25f61, #f2cb5f, #d4632e, #5ff281, #5ff2f0);
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: 50%;
    animation: moveGradient 4s alternate infinite;
}
@keyframes moveGradient {
    50% {
        background-position: 100% 50%;
    }
}
.fixed-profile .dropdown-menu.md-container{
    top: 11px !important;
    min-width: 15rem;
    right: auto;
    padding: 10px !important;
    z-index: 1100;
}
.fixed-profile .dropdown-menu.md-container:after {
    position: absolute;
    content: '';
    top: -8px;
    height: 0;
    width: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 15px solid #fff;
    left: 8px;
    right: auto;
    border-bottom-color: #ffffff;
}
.fixed-profile .dropdown-menu.nd-container {
    top: 11px !important;
    min-width: 15rem;
    left: auto !important;
    padding: 10px !important;
    right: 0;
}
.fixed-profile .dropdown-menu.nd-container:after {
    position: absolute;
    content: '';
    top: -8px;
    height: 0;
    width: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 15px solid #fff;
    right: 104px;
    left: auto;
    border-bottom-color: #ffffff;
}
.fixed-profile .dropdown-menu .nav-drop .inner .nav-drop-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 16px 10px;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
    background: #fff;
}
.fixed-profile .dropdown-menu .nav-drop .inner .nav-drop-body.account-items {
    padding: 10px 0;
}
.fixed-profile .dropdown-menu .nav-drop .inner .nav-drop-body {
    max-height: 445px;
    padding: 0 10px;
    overflow-y: auto;
}
.fixed-profile .dropdown-menu .nav-drop .inner .nav-drop-body.account-items .account-item {
    padding: 10px 10px 10px;
    display: block;
    line-height: 1.2;
}
.fixed-profile .dropdown-menu .nav-drop .inner .nav-drop-body.account-items a.account-item:hover {
    background: #f2f4ff;
    border-radius: 6px;
    cursor: pointer;
}
.fixed-profile .dropdown-menu .media img {
    width: 34px;
    height: 34px;
    margin-right: 11px;
}
.fixed-profile .dropdown-menu .media .media-body {
    align-self: center;
}
.fixed-profile .dropdown-menu .inner .nav-drop-body.account-items hr {
    margin: .4rem 0 !important;
}
.sidebar-wrapper .menu-categories li.menu > .dropdown-toggle {
    color: #2f44b2;
    border-radius: 10px;
}
.sidebar-wrapper .menu-categories li.menu.active > .dropdown-toggle {
    color: #2f44b2;
    border-radius: 10px;
}
.sidebar-wrapper .menu-categories li.menu > a i {
    font-size: 28px;
}
#sidebar ul.menu-categories li.menu {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    position: relative;
    transition: all ease 0.2s;
    width: 50%;
}
#sidebar ul.menu-categories li.menu:last-child {
    margin-bottom: 0px;
}
.sidebar-wrapper .menu-categories li.menu > a {
    height: 86px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
    transition: all ease 0.4s;
    width: 80%;
    text-align: center;
    z-index: 2;
}
.sidebar-wrapper .menu-categories li.menu > a:hover,
.sidebar-wrapper .menu-categories li.menu > a.active{
    transition: all ease 0.4s;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px 0 rgb(85 85 85 / 8%), 0 1px 20px 0 rgb(0 0 0 / 7%), 0px 1px 11px 0px rgb(0 0 0 / 7%);
}
.sidebar-wrapper .menu-categories li.menu > a[data-active="true"] {
    background-image: linear-gradient(#5f72d2, #2a40af);
    transition: all ease 0.2s;
    border-radius: 10px;
    color: #ffffff !important;
    box-shadow: 0 4px 6px 0 rgb(85 85 85 / 8%), 0 1px 20px 0 rgb(0 0 0 / 7%), 0px 1px 11px 0px rgb(0 0 0 / 7%);
}
.sidebar-wrapper .menu-categories li.menu > a span{
    font-size: 12px;
    display: inline-block;
    margin-top: 8px;
    font-weight: 500;
}
.sidebar-wrapper .menu-categories li.menu > a.active span{
    font-weight: 400;
}
.main-container.sidebar-closed .sidebar-wrapper{
   left: -280px;
}
.main-container.sidebar-closed #content{
    margin-left: 0px;
}
.main-container.sidebar-closed .header-container.fixed-top {
    left: 0px;
    transition: all ease .600s;
}
.sub-sidebar-open .sidebar-submenu {
    transition: .3s ease all;
    visibility: visible;
    opacity: 1;
}
.sidebar-submenu {
    height: calc(100vh - 240px);
    background: #ffffff;
    transition: .3s ease all;
    z-index: 1027;
    visibility: hidden;
    position: relative;
    opacity: 0;
    box-shadow: 0 4px 6px 0 rgb(85 85 85 / 8%), 0 1px 20px 0 rgb(0 0 0 / 7%), 0px 1px 11px 0px rgb(0 0 0 / 7%);
}
.sidebar-submenu span.sidebar-submenu-close {
    background: #ebeeff;
    color: #2c42ad;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 50%;
    position: absolute;
    right: 12px;
    top: 14px;
    transition: all ease 0.3s;
}
.sidebar-submenu span.sidebar-submenu-close:hover {
    background: #2c42ad;
    color: #ebeeff;
    transition: all ease 0.3s;
    cursor: pointer;
}
.sidebar-submenu .submenu .submenu-inner-info {
    margin-top: 20px;
    margin-bottom: 0px;
    padding: 0px 20px 0px;
}
.sidebar-submenu .submenu .submenu-inner-info h5,
.sidebar-submenu .submenu .submenu-inner-info p {
    color: #2f44b2;
}
.sidebar-submenu .submenu {
    display: none;
    transition: all ease 0s;
}
.sidebar-submenu .submenu.show {
    display: block;
    transition: all ease 1s;
}
.submenu .submenu-info ul.submenu-list {
    padding: 12px 12px 40px;
}
.submenu .submenu-info ul.submenu-list li {
    padding: 9px 20px;
    margin-bottom: 5px;
}
.submenu .submenu-info ul.submenu-list li a {
    color: #2f44b2;
    width: 100%;
    display: flex;
}
.submenu .submenu-info ul.submenu-list li.active {
    background-color: #2f44b2 !important;
    border-radius: 10px;
}
.submenu .submenu-info ul.submenu-list li.active > a{
    font-weight: 500;
    color: #ffffff;
}
.submenu .submenu-info ul.submenu-list li.active:hover > a{
    font-weight: 500;
    color: #ffffff;
}
.submenu .submenu-info ul.submenu-list li:hover {
    background-color: #f1f4ff;
    border-radius: 10px;
}
.submenu .submenu-info ul.submenu-list li:hover > a{
    color: #2f44b2;
}
.submenu .submenu-info ul.submenu-list li::marker{
    color: transparent !important;
}
.submenu .submenu-info ul.submenu-list li:hover::marker {
    color: #2f44b2;
}
.submenu .submenu-info ul.submenu-list li > a[aria-expanded="true"] {
    font-weight: 500;
    color: #2f44b2; 
}
.submenu .submenu-info ul.submenu-list li > a.dropdown-toggle.active {
    font-weight: 600;
}
.submenu .submenu-info ul.submenu-list li > a.dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.submenu .submenu-info ul.submenu-list li > .dropdown-toggle[aria-expanded="false"] .sidemenu-right-icon {
    transform: rotate(0deg);
    transition: all ease 0.4s;
}
.submenu .submenu-info ul.submenu-list li > .dropdown-toggle[aria-expanded="true"] .sidemenu-right-icon {
    transform: rotate(90deg);
    transition: all ease 0.4s;
}
.submenu .submenu-info ul.submenu-list li ul.sub-submenu-list {
    padding-left: 0px;
    margin: 0px -20px;
}
.submenu .submenu-info ul.submenu-list li ul.sub-submenu-list li {
    padding-left: 40px;
}
.submenu .submenu-info ul.submenu-list li ul.sub-submenu-list li:first-child{
    margin-top: 10px;
}
.submenu .submenu-info ul.submenu-list li ul.sub-submenu-list li ul.sub-sub-submenu-list{
    padding-left: 0px;
}
.main-item {
    position: relative;
}
.main-item .new-notification {
    height: 8px;
    width: 8px;
    position: absolute;
    background: #2f44b2;
    border-radius: 50%;
    right: 12px;
    top: 2px;
}
.sidebar-submenu .menu-badge.badge-danger {
    background: #2f44b2;
}
.sidebar-submenu .menu-badge {
    font-size: 9px;
    padding: 3px 7px 2px;
    border-radius: 10px;
}
.right-bar .media {
    padding: .75rem 0rem;
    margin: 0px;
}
.right-bar .media img.avatar-sm {
    height: 2.25rem;
    width: 2.25rem;
    margin-right: 0px;
    border: 0px;
}
.main-container.sub-sidebar-open #sidebar ul.menu-categories {
    display: none;
}
#sidebar ul.menu-categories {
    position: relative;
    margin: auto;
    width: 100%;
    height: calc(100vh - 240px)!important;
    margin-right: 0;
    overflow: hidden;
    padding: 20px 0px 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
#sidebar .ps__thumb-y {
    right: 4px;
    display: none;
}
.overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,.4);
    z-index: 1020!important;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    touch-action: pan-y;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.overlay.show {
    display: block;
    opacity: 1;
}
.e-animated{-webkit-animation-duration:0.6s;animation-duration:0.6s;-webkit-animation-fill-mode:both;animation-fill-mode:both}
@-webkit-keyframes e-fadeInUp {
    0% {
        opacity: 0;
        margin-top: 10px;
    }
    100% {
        opacity: 1;
        margin-top: 0;
    }
}
@keyframes e-fadeInUp {
    0% {
        opacity: 0;
        margin-top: 10px;
    }
    100% {
        opacity: 1;
        margin-top: 0;
    }
}
.e-fadeInUp {
    -webkit-animation-name: e-fadeInUp;
    animation-name: e-fadeInUp
}
#sidebar ul.menu-categories .ps__rail-y {
    right: -4px !important;
}
/* Right Bar */
.right-bar-enabled .right-bar {
    right: 0;
}
.right-bar {
    background-color: #fff;
    box-shadow: 0 0 24px 0 rgba(0,0,0,.1), 0 1px 0 0 rgba(0,0,0,.1);
    display: block;
    position: fixed;
    transition: all .2s ease-out;
    width: 260px;
    z-index: 9999;
    float: right!important;
    right: -270px;
    top: 0;
    bottom: 0;
}
.right-bar .nav-link {
    background: #2f44b2;
    border-left: 1px solid rgb(255 255 255 / 45%);
    transition: all ease 0.4s;
}
.right-bar .nav-link.active {
    background: #ffffff;
    border: 1px solid transparent;
}
.right-bar .nav-link i {
    font-size: 24px;
    color: #ffffff;
}
.right-bar .nav-link.active i {
    font-size: 24px;
    color: #2f44b2;
}
.right-bar .tab-content {
    background: #ffffff;
    margin-top: 0px;
    border-left: 1px solid #dee2e6;
}
.right-bar .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    background: #4a5ec5;
    transition: all ease 0.4s;
    border: 1px solid transparent;
}
.right-bar .nav-tabs .nav-link:focus, .nav-tabs .nav-link.active:hover {
    background: #ffffff;
    transition: all ease 0.4s;
    border: 1px solid transparent;
}
.right-bar .right-bar-heading {
    font-size: 14px;
    color: #cccccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
}
.right-bar-heading a {
    color: #b1c8eb;
    padding-top: 3px;
}
.right-bar .media {
    padding: .75rem 0rem;
}
.right-bar .user-status.online, .chat-system .own-area .user-status.online {
    background-color: #1abc9c;
}
.right-bar .user-status, .chat-system .own-area .user-status {
    position: absolute;
    right: 0;
    top: 2px;
    font-size: 10px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
}
.right-bar .group-item i{
    vertical-align: middle;
}
.rightbar-overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,.4);
    z-index: 1030!important;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    touch-action: pan-y;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rightbar-overlay.show {
    display: block;
    opacity: .7;
}
.right-bar .search-bar input.search-form-control {
    width: 100%;
    font-size: 12px;
    background-color: #dbe1ff;
    border: none;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 10px;
    color: #2f44b2;
    padding: 0px 4px 0px 15px;
    height: 36px;
    font-weight: 500;
}
.right-bar .search-bar input.search-form-control:focus {
    border: 1px solid #dbe1ff;
    box-shadow: none;
}
.right-bar .search-bar input.search-form-control {
    border: 1px solid #dbe1ff;
    width: 100%;
}
.right-bar .search-bar input.search-form-control::-webkit-input-placeholder {
    color: #2f44b2;
    font-size: 12px;
}
.right-bar .search-bar input.search-form-control::-ms-input-placeholder {
    color: #2f44b2;
    font-size: 12px;
}
.right-bar .search-bar input.search-form-control::-moz-placeholder {
    color: #2f44b2;
    font-size: 12px;
}
.right-bar .search-bar input.search-form-control::-webkit-input-placeholder {
    color: #2f44b2;
}
.right-bar .search-bar input.search-form-control::-ms-input-placeholder {
    color: #2f44b2;
}
.right-bar .search-bar input.search-form-control::-moz-placeholder {
    color: #2f44b2;
}
.rightbar-tab-container .ps {
    height: calc(100vh - 63px)!important;
    margin-right: 0;
    padding-right: 0px;
    border-right: 0px solid #e0e6ed;
}
.rightbar-tab-container .rightbar-tab {
    position: relative;
    margin: auto;
    width: 100%;
    height: calc(100vh - 40px)!important;
    /* padding: 24px 0 0 0; */
    overflow: hidden;
}
.rightbar-tab-container * {
    overflow: hidden;
    white-space: nowrap;
}
/* Switch */
 .switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 18px;
    margin-bottom: 0px;
}
 .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}
 .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
 .slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
 input:checked + .slider.primary-switch {
    background-color: #2f44b2;
}
 input:focus + .slider.primary-switch {
    box-shadow: 0 0 1px #2f44b2;
}
 input:checked + .slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
}
/* Rounded sliders */
 .slider.round {
    border-radius: 34px;
}
 .slider.round:before {
    border-radius: 50%;
}
.switch-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.switch-container p {
    margin-bottom: 0px;
    width: 160px;
    text-overflow: ellipsis;
}
/* Responsive Msg Component  */
.responsive-msg-component {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    background-color: rgb(0 0 0 / 33%);
    padding: 2rem;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}
.responsive-msg-component p {
    background: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    color: #828282;
    font-weight: 500;
    position: relative;
    font-size: 13px;
}
.responsive-msg-component p a.close-msg-component {
    position: absolute;
    top: -13px;
    right: -13px;
    color: #ffffff;
    background: #2f44b2;
    font-size: 16px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
/* Footer-wrapper */
.footer-wrapper {
    padding: 10px 20px 10px 18px;
    display: inline-block;
    background: transparent;
    font-weight: 600;
    font-size: 12px;
    width: 100%;
    border-top-left-radius: 6px;
    display: flex;
    justify-content: space-between;
}
.main-container.sidebar-closed .footer-wrapper {
    border-radius: 0;
}
.footer-wrapper .footer-section p {
    margin-bottom: 0;
    color: #bfbfbf;
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 500;
}
.footer-wrapper .footer-section p a {
    color: #a0a0a0;
}
.footer-wrapper .footer-section svg {
    color: #2f44b2;
    fill: #6c80e9;
    width: 15px;
    height: 15px;
    vertical-align: text-top;
}
.social-media .text-primary {
    color: #2262c6 !important;
}
/* .table-responsive {
    background: #ffffff;
} */
/* MEDIA QUERIES */
@media (min-width: 992px) {
    .sidebar-closed #sidebar .theme-brand li.theme-text a { display: none; }
    .d-lg-flex {
        display: flex!important;
    }
    .header-container.fixed-top {
        left: 0px;
        padding-left: 298px;
    }
}
@media (max-width: 991px) {
    .navbar .theme-brand {
        padding: 0.9px 0 0.9px 14px!important;
        margin-left: 0px !important;
    }
    .sub-header-container .navbar .toggle-sidebar, .sub-header-container .navbar .sidebarCollapse {
        position: relative;
        padding: 0 15px 0 0px;
    }
    .navbar .navbar-item .nav-drop.is-notification-dropdown {
        width: 205px;
    }
    .navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu {
        min-width: 12rem;
    }
    .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu {
        min-width: 12rem;
        left: -164px;
    }
    .navbar .navbar-item .nav-drop.is-account-dropdown {
        width: 190px;
    }
    .navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu {
        min-width: 11rem;
    }
    /* NavBar */
    .main-container.sidebar-closed #content { margin-left: 0; }
    /* Sidebar */
    .shadow-bottom { width: 100%; }
    #content { margin-left: 0px; width: 100%; }
    .sub-sidebar-open #content {
        width: 100%;
        margin-left: 0px;
    }
    .sbar-open #content {
        width: 100%;
    }
    body.alt-menu .sidebar-closed > .sidebar-wrapper { width: 0; left: -52px; }
    .main-container { padding: 0;  }
    #sidebar ul.menu-categories.ps {
        height: calc(100vh - 240px)!important;
    }
    .sidebar-closed > .sidebar-wrapper {
        left: 0;
    }
    .sidebar-wrapper {
        left: 0;
        bottom: 0px;
        background-color: #ffffff;
        border-radius: 0px;
    }
    .sidebar-wrapper .fixed-profile{
        background-color: #677ada;
        height: 225px;
    }
    .sidebar-noneoverflow { overflow: hidden; }
    #sidebar {
        height: 100vh!important;
        padding: 0px 0 10px 0px;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
    }
    .footer-wrapper {
        padding: 0 16px 17px 16px;
    }
    .layout-px-spacing {
        padding: 0 16px!important;
    }
    nav#sidebar {
        padding-right: 0px;
    }
    .sidebar-submenu {
        padding: 20px 15px;
    }
    .submenu-inner-info h5 {
        font-size: 1rem;
    }
    .submenu-inner-info p {
        font-size: 0.8rem;
    }
    .submenu .submenu-info ul.submenu-list li a {
        font-size: 12px;
    }
    .sidebar-wrapper .menu-categories li.menu > a i {
        font-size: 25px;
    }
    .sidebar-wrapper .menu-categories li.menu > a {
        padding: 10px 12px;
        background: transparent;
        transition: all ease 0.4s;
    }
    .sidebar-wrapper .menu-categories li.menu > a[data-active="true"], 
    .sidebar-wrapper .menu-categories li.menu > a:hover, 
    .sidebar-wrapper .menu-categories li.menu > a.active {
        padding: 10px 12px;
    }
    .sidebar-submenu .submenu .submenu-inner-info {
        margin-top: 3px;
        margin-bottom: 20px;
    }
    .header-container.fixed-top {
        left: 0px;
        padding-left: 100px;
    }
    .navbar-expand-sm {
        justify-content: space-between;
    }
    .sub-sidebar-open .header-container.fixed-top {
        left: 0px;
        transition: all ease .600s;
    }
    /* display .overlay when it has the .active class */
    .overlay.show {
        display: block;
        opacity: 1;
    }
    .sidebar-submenu span.sidebar-submenu-close {
        right: 12px;
        top: 14px;
    }
    .fixed-profile .profile-option-container .option-link-container p {
        font-size: 11px;
    }
    .fixed-profile .profile-option-container .option-link-container h6 {
        font-size: 12px;
    }
}
@media (max-width: 370px) {
    .navbar .navbar-item .nav-item .nav-link.rightbarCollapse {
        padding: 0 20px 0 0px;
        position: relative;
        line-height: 1;
    }
}
@media (max-width: 767px) {
    .navbar .theme-brand li.theme-text {
        display: none;
        margin-right: 0;
    }
    .navbar .navbar-item .nav-item .form-inline.search .search-form-control {
        opacity: 0;
        transition: opacity 200ms, top 200ms
    }
    .navbar .navbar-item .nav-item .form-inline.search.input-focused .search-form-control {
        opacity: 1;
        transition: opacity 200ms, top 200ms
    }
    .navbar .navbar-item .nav-item .form-inline.search {
        opacity: 0;
        transition: opacity 200ms, top 200ms;
        top: -25px;
    }
    .navbar .navbar-item .nav-item .form-inline.search.input-focused {
        position: absolute;
        bottom: 0;
        top: 0;
        background: #dedede;
        border-bottom: 1px solid #dedede;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 32;
        margin-top: 0px!important;
        display: flex;
        opacity: 1;
        transition: opacity 200ms, top 200ms;
    }
    .navbar .navbar-item .nav-item.search-animated {
        position: initial;
    }
    .navbar .navbar-item .nav-item.search-animated i {
        font-weight: 600;
        margin: 0px 9px 0px 16px;
        cursor: pointer;
        color: #ffffff;
        position: relative;
        font-size: 22px;
        top: 3px;
    }
    .navbar .navbar-item .nav-item.search-animated.show-search i {
        margin: 0;
        position: absolute;
        top: 17px;
        left: 16px;
        font-size: 22px;
        color: #2f44b2;
        z-index: 40;
    }
    .navbar .navbar-item .nav-item .form-inline.search.input-focused .search-bar { width: 100%; }
    .navbar .navbar-item .nav-item .form-inline.search.input-focused .search-form-control {
        background: transparent;
        display: block;
        padding-left: 50px;
        padding-right: 12px;
        border: none;
        color: #2f44b2;
        font-size: 13px;
    }
    .navbar .navbar-item .nav-item .form-inline.search.input-focused .search-form-control::placeholder {
        color: #2f44b2;
        font-size: 13px;
    }
    .navbar .navbar-item .nav-item .form-inline.search .search-form-control {
        border: none;
        width: 100%;
        display: none;
    }
    .sidebar-submenu  {
        padding: 20px 0px;
    }
    .logo-area {
        height: unset;
    }
    .navbar-expand-sm .navbar-item .nav-link {
        padding: 0 0.2rem;
    }
    .navbar .navbar-item .nav-item.dropdown.message-dropdown, 
    .navbar .navbar-item .nav-item.dropdown.fullscreen-dropdown {
        margin-left: 5px;
    }
    .navbar .navbar-item .nav-item.dropdown.notification-dropdown {
        margin-left: 5px;
    }
    .navbar .navbar-item .nav-item .nav-link.user {
        padding: 0 10px 0 14px;
    }
    .header-container .navbar .language-dropdown {
        margin-left: 8px;
    }
    .sub-sidebar-open .sidebar-submenu {
        left: 0px;
    }
    .main-container::before {
        position: fixed;
    }
    .header-container.fixed-top {
        padding-left: 0px;
    }
    .flatpickr-calendar {
        width: 334px !important;
        transform: scale(0.9);
        left: -10px !important;
        top: 136px !important;
        overflow: hidden;
        transition: all ease 0.2s;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }
}
@media (max-width: 575px) {
    .navbar .navbar-item .nav-item.message-dropdown .dropdown-menu { min-width: 16.2rem;  }
    .sub-header-container .breadcrumb-one .breadcrumb-item:not(.active) { display: none;  }
    .sub-header-container .breadcrumb-one .breadcrumb-item+.breadcrumb-item::before { display: none; }
    .sub-header-container .breadcrumb-one .breadcrumb-item span {
    vertical-align: sub;
    font-size: 15px;
    font-weight: 700; }
    .sub-header-container .navbar .toggle-sidebar, .sub-header-container .navbar .sidebarCollapse { padding: 0 13px 0 16px;}
    .navbar .navbar-item .nav-item.dropdown .dropdown-menu:after { right: 45px; }
    .navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu { right: -44px; }
    .navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu:after { right: 54px; }
    .navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu { right: -80px; }
    .navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu:after { right: 89px; }
    .footer-wrapper .footer-section.f-section-2 { display: none; }
}
/* Tablet Media Query */
@media (min-width: 600px) and (max-width: 768px) {
    .navbar .navbar-item .nav-item .nav-link.rightbarCollapse {
        padding: 0 20px 0 0px;
    }
    .sidebar-submenu {
        width: 220px;
    }
}
@media (min-width: 1100px) {
    .navbar .navbar-item .nav-item .form-inline.search .search-form-control {
        width: 380px;
    }
}
@media (max-width: 1068px) {
    .responsive-msg-component {
        display: flex;
    }
}