html { min-height: 100% }
body {
    color: #888ea8;
    height: 100%;
    font-size: 0.875rem;
    background: #f1f2f3;
    overflow-x: hidden;
    overflow-y: auto;
    letter-spacing: 0.0312rem;
}
h1, h2, h3, h4, h5, h6 { color: #3b3f5c; }
:focus { outline: none; }
::-moz-selection { /* Code for Firefox */
  color: #2f44b2;
  background: transparent;
}
::selection {
  color: #2f44b2;
  background: transparent;
}
:root{
  --thm-green:#68b90b;
  --thm-lt-green:#60a730;
  --thm-meroon:#711750;
  --thm-blue:#001655;
  --thm-yellow:#ffff00;
}

.txt-blue{
    color: var(--thm-blue);
}
.txt-yellow{
    color: var(--thm-yellow);
}
p { margin-top: 0; margin-bottom: 0.625rem; color: #515365 }
hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid #f1f2f3;
}
strong { font-weight: 600; }
code { color: #e7515a; }
.mb-m-4 { margin-bottom: -1.5rem;}
select.form-custom::-ms-expand {
    display: none;
}
.custom-file-input:focus~.custom-file-label {
    border: 1px solid #ced4da;
    box-shadow: none;
}
.custom-file-input:focus~.custom-file-label::after {
    border: none;
    border-left: 1px solid #ced4da;
}
.lead a.btn.btn-primary.btn-lg {
    margin-top: 15px;
    border-radius: 4px;
}
.jumbotron { background-color: #f1f2f3; }
.mark, mark { background-color: #ffeccb }
.code-section-container {
    margin-top: 20px;
    text-align: left;
}
.toggle-code-snippet {
    border: none;
    background-color: transparent!important;
    padding: 0px!important;
    box-shadow: none!important;
    color: #888ea8!important;
    margin-bottom: -24px;
    border-bottom: 1px dashed #bfc9d4;
    border-radius: 0;
}
.toggle-code-snippet svg {
    color: #2f44b2;
}
.code-section {
    padding: 0;
    height: 0;
}
.code-section-container.show-code .code-section {
    margin-top: 20px;
    height: auto;
}
.code-section pre {
    margin-bottom: 0;
    height: 0;
    padding: 0;
    border-radius: 6px;
}
.code-section-container.show-code .code-section pre {
    height: auto;
    padding: 22px;
}
.code-section code { color: #fff; }
/* Scroll to top arrow */
.scroll-top-arrow {
    cursor: pointer;
    position: fixed;
    bottom: 25px;
    right: 10px;
    background: #677ada;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
    z-index: 9;
}
.responsive-msg-component {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    background-color: rgb(0 0 0 / 33%);
    padding: 2rem;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}
.responsive-msg-component p {
    background: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    color: #828282;
    font-weight: 500;
    position: relative;
    font-size: 13px;
}
.responsive-msg-component p a.close-msg-component {
    position: absolute;
    top: -13px;
    right: -13px;
    color: #ffffff;
    background: #677ada;
    font-size: 16px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
@media (max-width: 1068px) {
    .responsive-msg-component {
        display: flex;
    }
}
/*blockquote*/
blockquote.blockquote { 
    color: #0e1726;
    padding: 20px 20px 20px 14px;
    font-size: 0.875rem;
    background-color: #ffffff;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid #e0e6ed;
    border-left: 2px solid #2f44b2;
    -webkit-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
}
blockquote.blockquote > p {
    margin-bottom: 0;
}
blockquote .small:before, blockquote footer:before, blockquote small:before {
    content: '\2014 \00A0';
}
blockquote .small, blockquote footer, blockquote small {
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #777;
}
blockquote.media-object.m-o-border-right {
    border-right: 4px solid #2f44b2;
    border-left: none;
}
blockquote.media-object .media .usr-img img {
    width: 55px;
}
/* Icon List */
.list-icon {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}
.list-icon li:not(:last-child) {
    margin-bottom: 15px;
}
.list-icon svg {
    width: 18px;
    height: 18px;
    color: #2f44b2;
    margin-right: 2px;
    vertical-align: sub;
}
.list-icon .list-text {
    font-size: 14px;
    font-weight: 600;
    color: #515365;
    letter-spacing: 1px;
}
a {
    color: #515365;
    outline: none;
}
a:hover {
    color: #555555;
    text-decoration: none
}
a:focus {
    outline: none;
    text-decoration: none
}
button:focus, textarea, textarea:focus {
    outline: none;
}
.btn-link:hover {
    text-decoration: none
}
span.blue {
    color: #2f44b2
}
span.green {
    color: #8dbf42
}
span.red {
    color: #e7515a
}
.form-group label, label {
    font-size: 14px;
    color: #333;
    /* letter-spacing: 1px; */
}
.form-group label.checkbox, .form-group label.radio {
    margin-bottom: 0px;
    display: flex;
}
.form-text {
    display: block;
    margin-top: .25rem;
    font-size: 0.7rem;
}
.custom-control-input:disabled~.custom-control-label {
    color: #d3d3d3;
}
.form-control {
    height: auto;
    border: 1px solid #c5cad2;
    color: #333;
    font-size: 14px;
    padding: 6px 3px;
    /* letter-spacing: 1px; */
    height: calc(1.2em + 1.2rem + 2px);
/*    padding: .75rem 1.25rem;*/
    border-radius: 6px;
}
.form-control:focus {
    box-shadow: none;
    border-color: #ccc;
    color: #333;
}
.form-control::-webkit-input-placeholder { color: #ccc; font-size: 14px; }
.form-control::-ms-input-placeholder { color: #ccc; font-size: 14px; }
.form-control::-moz-placeholder { color: #ccc; font-size: 14px; }
.form-control:focus::-webkit-input-placeholder { color: #ccc; font-size: 14px; }
.form-control:focus::-ms-input-placeholder { color: #ccc; font-size: 14px; }
.form-control:focus::-moz-placeholder { color: #ccc; font-size: 14px; }
.form-control.form-control-lg {
    font-size: 19px;
    padding: 11px 20px;;
}
.form-control.form-control-sm {
    padding: 7px 16px;
    font-size: 12px;
}
.form-control.form-control-sm::-webkit-input-placeholder {font-size: 12px;}
.form-control.form-control-sm::-ms-input-placeholder {font-size: 12px;}
.form-control.form-control-sm::-moz-placeholder {font-size: 12px;}
.custom-select {
    height: auto;
    border: 1px solid #f1f2f3;
    color: #3b3f5c;
    font-size: 15px;
    padding: 8px 10px;
    letter-spacing: 1px;
    background-color: #f1f2f3;
}
.custom-select.custom-select-lg {
    font-size: 18px;
    padding: 16px 20px;
}
.custom-select.custom-select-sm {
    font-size: 13px;
    padding: 7px 16px;
}
.custom-select:focus {
    box-shadow: none;
    border-color: #2f44b2;
    color: #3b3f5c;
}
.form-control-file {
    width: 100%;
    color: #5c1ac3;
}
.form-control-file::-webkit-file-upload-button {
    letter-spacing: 1px;
    padding: 9px 20px;
    text-shadow: none;
    font-size: 12px;
    color: #fff;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    transition: .2s ease-out;
    touch-action: manipulation;
    cursor: pointer;
    background-color: #5c1ac3;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
    will-change: opacity, transform;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    border-radius: 4px;
    border: transparent;
    outline: none;
}
.form-control-file::-ms-file-upload-button {
    letter-spacing: 1px;
    padding: 9px 20px;
    text-shadow: none;
    font-size: 14px;
    color: #fff;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    transition: .2s ease-out;
    touch-action: manipulation;
    cursor: pointer;
    background-color: #5c1ac3;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
    will-change: opacity, transform;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    border-radius: 4px;
    border: transparent;
    outline: none;
}
.form-control-file.form-control-file-rounded::-webkit-file-upload-button {
    -webkit-border-radius: 1.875rem !important;
    -moz-border-radius: 1.875rem !important;
    -ms-border-radius: 1.875rem !important;
    -o-border-radius: 1.875rem !important;
    border-radius: 1.875rem !important;
}
select.form-control.form-custom {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    vertical-align: middle;
    /* background: #fff url(../img/arrow-down.png) no-repeat right .75rem center; */
    background-size: 13px 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.custom-file { height: auto; }
.custom-file input { height: auto; }
.custom-file-label {
    height: auto;
    border: 1px solid #f1f2f3;
    color: #3b3f5c;
    font-size: 15px;
    padding: 8px 10px;
    letter-spacing: 1px;
    background-color: #f1f2f3;
}
.custom-file-label::after {
    height: auto;
    padding: 8px 12px;
    color: #515365;
}
.input-group button:hover, .input-group .btn:hover,
.input-group button:focus, .input-group .btn:focus {
    transform: none;
}
.input-group .input-group-prepend .input-group-text {
    background-color: #F3F6F9;
    border: 1px solid #E4E6EF;
    font-size: 13px;
}
.input-group .input-group-prepend .input-group-text svg { color: #888ea8; }
.input-group:hover .input-group-prepend .input-group-text svg {
    color: #2f44b2;
    fill: rgba(27, 85, 226, 0.23921568627450981);
}
.input-group .input-group-append .input-group-text {
    background-color: #F3F6F9;
    border-color: #ffecec;
    font-size: 13px;
}
.input-group .input-group-append .input-group-text svg { color: #888ea8; }
.input-group:hover .input-group-append .input-group-text svg {
    color: #2f44b2;
    fill: rgba(27, 85, 226, 0.23921568627450981);
}
.was-validated select.form-control:valid {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}
.was-validated select.form-control:invalid {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}
.input-error {
    border: 1px solid #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}
.input-success {
    border: 1px solid #28a745;
    padding-right: calc(1.5em + .75rem);
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}
/* Text Editor */
.editor-toolbar {
    background: whitesmoke;
    z-index: 99;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.CodeMirror {
    border-top: 0px !important;
    margin-top: -7px !important;
}
.ql-container {
    height: unset !important;
    border-top: 0px !important;
    margin-top: -7px !important;
}
.ql-toolbar {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background: whitesmoke;
}
.dropdown-toggle:after { display: none; }
.dropup .dropdown-toggle::after { display: none; }
.dropright .dropdown-toggle::after { display: none; }
.dropleft .dropdown-toggle::before { display: none; }
.dropdown-toggle svg.feather[class*="feather-chevron-"] {
    width: 15px;
    height: 15px;
    vertical-align: middle;
}
.btn {
    padding: 0.4375rem 1.25rem;
    text-shadow: none;
    font-size: 14px;
    color: #3b3f5c;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    transition: .2s ease-out;
    touch-action: manipulation;
    cursor: pointer;
    background-color: #f1f2f3;
    will-change: opacity, transform;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
}
.btn-label {
    margin: -.55rem .9rem -.55rem -1.3rem;
    padding: .6rem .9rem;
    background-color: rgba(50,58,70,.1);
}
.btn-label-right {
    margin: -.55rem -1.3rem -.55rem .9rem;
    padding: .6rem .9rem;
    background-color: rgba(50,58,70,.1);
    display: inline-block;
}
.btn.rounded-circle {
    height: 40px;
    width: 40px;
    padding: 8px 8px;
}
.btn:hover,
.btn:focus {
    color: #3b3f5c;
    background-color: #f1f2f3;
    border-color: #d3d3d3;
    opacity: 0.9;
}
.btn-group .btn:hover,
.btn-group .btn:focus {
    -webkit-transform: none;
    transform: none;
}
.btn.disabled,
.btn.btn[disabled] {
    background-color: #f1f2f3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.13)
}
.btn.disabled:hover,
.btn.btn[disabled]:hover {
    cursor: not-allowed
}
.btn .caret {
    border-top-color: #0e1726;
    margin-top: 0;
    margin-left: 3px;
    vertical-align: middle
}
.btn+.caret,
.btn+.dropdown-toggle .caret { margin-left: 0 }
.btn-group>.btn, .btn-group .btn { padding: 8px 14px; }
.btn-group-lg>.btn, .btn-group-lg .btn { font-size: 1.125rem; }
.btn-group-lg>.btn, .btn-lg,
.btn-group>.btn.btn-lg,
.btn-group .btn.btn-lg {
    padding: .625rem 1.5rem;
    font-size: 16px;
}
.btn-group-lg>.btn, .btn-group-lg .btn { font-size: 1.125rem; }
.btn-group-sm>.btn, .btn-sm, .btn-group>.btn.btn-sm, .btn-group .btn.btn-sm { font-size: 0.6875rem; }
.btn-group .dropdown-menu {
    border: 1px solid #e0e6ed;
    box-shadow: rgba(113, 106, 202, 0.2) 0px 0px 15px 1px;
    padding: 10px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
}
.btn-group .dropdown-menu a.dropdown-item {
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    color: #888ea8;
    padding: 11px 8px;
}
.btn-group .dropdown-menu a.dropdown-item:hover {
    background-color: #f1f2f3;
}
.btn-group .dropdown-menu a.dropdown-item svg {
    cursor: pointer;
    color: #888ea8;
    margin-right: 6px;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    fill: rgba(0, 23, 55, 0.08);
}
.btn-group .dropdown-menu a.dropdown-item:hover svg {
    color: #2f44b2;
}
.dropdown:not(.custom-dropdown-icon) .dropdown-menu {
    border: none;
    border: 1px solid #e0e6ed;
    z-index: 899;
    box-shadow: rgba(113, 106, 202, 0.2) 0px 0px 15px 1px;
    padding: 10px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
}
.dropdown:not(.custom-dropdown-icon) .dropdown-menu a.dropdown-item {
    font-size: 12px;
    font-weight: 700;
    color: #888ea8;
    padding: 11px 8px;
}
.dropdown:not(.custom-dropdown-icon) .dropdown-menu a.dropdown-item.active,
.dropdown:not(.custom-dropdown-icon) .dropdown-menu a.dropdown-item:active {
    background-color: transparent;
    color: #3b3f5c;
    font-weight: 700;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus,
.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show>.btn-success.dropdown-toggle:focus,
.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show>.btn-info.dropdown-toggle:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show>.btn-danger.dropdown-toggle:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .show>.btn-warning.dropdown-toggle:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show>.btn-dark.dropdown-toggle:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-primary.dropdown-toggle:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-success.dropdown-toggle:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-info.dropdown-toggle:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-danger.dropdown-toggle:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-warning.dropdown-toggle:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-secondary.dropdown-toggle:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: none;
}
.btn.focus, .btn:focus,
.btn-success:focus,.btn-info:focus,
.btn-danger:focus,.btn-warning:focus,
.btn-secondary:focus,.btn-dark:focus,
.btn-outline-success:focus,.btn-outline-info:focus,
.btn-outline-danger:focus,.btn-outline-warning:focus,
.btn-outline-secondary:focus,.btn-outline-dark:focus
.btn-light-default:focus,.btn-light-primary:focus,
.btn-light-success:focus,.btn-light-info:focus,
.btn-light-danger:focus,.btn-light-warning:focus,
.btn-light-secondary:focus,.btn-light-dark:focus {
    box-shadow: none;
}
/*      Default Buttons       */
.btn-primary {
    color: #fff !important;
    background-color: #2f44b2!important;
    border-color: #2f44b2;
}
.btn-soft-primary {
    color: #ffffff !important;
    background-color: #7a8ce6!important;
    border-color: #7a8ce6 !important;
    box-shadow: none;
}
.btn-primary:hover,
.btn-primary:focus {
    color: #fff !important;
    background-color: #2f44b2;
    box-shadow: none;
    border-color: #2f44b2;
}
.btn-primary:active,
.btn-primary.active {
    background-color: #2f44b2;
    border-top: 1px solid #2f44b2
}
.btn-primary.disabled,
.btn-primary.btn[disabled],
.btn-primary:disabled {
    background-color: #2f44b2;
    border-color: #2f44b2;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover,
.btn-primary.focus:active, .btn-primary:active:focus, .btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
    color: #fff !important;
    background-color: #2aebcb;
    border-color: #2aebcb;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff !important;
    background-color: #2f44b2;
    border-color: #2f44b2;   
}
.btn-primary .caret { border-top-color: #fff }
.btn-group.open .btn-primary.dropdown-toggle { background-color: #c2d5ff }
.btn-secondary {
    color: #fff !important;
    background-color: #5c1ac3;
    border-color: #5c1ac3;
}
.btn-soft-secondary {
    color: #5c1ac3 !important;
    background-color: rgba(91, 26, 195, 0.18);
    border-color:  rgba(91, 26, 195, 0.18);
}
.btn-secondary:hover,
.btn-secondary:focus {
    color: #fff !important;
    background-color: #5c1ac3;
    box-shadow: none;
    border-color: #5c1ac3;
}
.btn-secondary:active,
.btn-secondary.active {
    background-color: #5c1ac3;
    border-top: 1px solid #5c1ac3
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    color: #fff !important;
    background-color: #5c1ac3;
    border-color: #5c1ac3;
}
.btn-secondary.disabled,
.btn-secondary.btn[disabled],
.btn-secondary:disabled {
    background-color: #5c1ac3;
    border-color: #5c1ac3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.btn-secondary .caret { border-top-color: #fff }
.btn-white {
    color: #666 !important;
    background-color: #ffffff;
    border-color: #ffffff;
}
.btn-white:hover,
.btn-white:focus {
    color: #666 !important;
    background-color: #ffffff;
    box-shadow: none;
    border-color: #ffffff;
}
.btn-link {
    color: #2f44b2 !important;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
}
.btn-link:hover,
.btn-link:focus {
    color: #2f44b2 !important;
    background-color: transparent;
    box-shadow: none;
    border-color: transparent;
}
.btn-info {
    color: #fff !important;
    background-color: #2196f3;
    border-color: #2196f3;
}
.btn-soft-info {
    color: #2196f3 !important;
    background-color: rgba(33, 149, 243, 0.18);
    border-color: rgba(33, 149, 243, 0.18);
}
.btn-info:hover,
.btn-info:focus {
    color: #fff !important;
    background-color: #2196f3;
    box-shadow: none;
    border-color: #2196f3;
}
.btn-info:active,
.btn-info.active {
    background-color: #2196f3;
    border-top: 1px solid #2196f3
}
.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show>.btn-info.dropdown-toggle {
    color: #fff !important;
    background-color: #2196f3;
    border-color: #2196f3;
}
.btn-info.disabled,
.btn-info.btn[disabled],
.btn-info:disabled {
    background-color: #2196f3;
    border-color: #2196f3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.btn-info.active.focus, .btn-info.active:focus, .btn-info.active:hover, .btn-info.focus:active, .btn-info:active:focus,
.btn-info:active:hover, .open > .dropdown-toggle.btn-info.focus, .open > .dropdown-toggle.btn-info:focus, .open > .dropdown-toggle.btn-info:hover {
    color: #fff !important;
    background-color: #2196f3;
    border-color: #2196f3
}
.btn-info .caret { border-top-color: #fff }
.btn-group.open .btn-info.dropdown-toggle { background-color: #bae7ff }
.btn-warning {
    color: #fff !important;
    background-color: #e2a03f;
    border-color: #e2a03f;
}
.btn-soft-warning {
    color: #e2a03f !important;
    background-color: rgba(226, 161, 63, 0.18);
    border-color: rgba(226, 161, 63, 0.18);
}
.btn-warning:hover,
.btn-warning:focus {
    color: #fff !important;
    background-color: #e2a03f;
    box-shadow: none;
    border-color: #e2a03f;
}
.btn-warning:active,
.btn-warning.active {
    background-color: #e2a03f;
    border-top: 1px solid #e2a03f
}
.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show>.btn-warning.dropdown-toggle {
    color: #0e1726;
    background-color: #e2a03f;
    border-color: #e2a03f;
}
.btn-warning.disabled,
.btn-warning.btn[disabled],
.btn-warning:disabled {
    background-color: #e2a03f;
    border-color: #e2a03f;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.btn-warning.active.focus, .btn-warning.active:focus, .btn-warning.active:hover, .btn-warning.focus:active, .btn-warning:active:focus,
.btn-warning:active:hover, .open > .dropdown-toggle.btn-warning.focus, .open > .dropdown-toggle.btn-warning:focus, .open > .dropdown-toggle.btn-warning:hover {
    color: #fff !important;
    background-color: #e2a03f;
    border-color: #e2a03f
}
.btn-warning .caret { border-top-color: #fff }
.btn-group.open .btn-warning.dropdown-toggle { background-color: #df8505 }
.btn-danger {
    color: #fff !important;
    background-color: #e7515a;
    border-color: #e7515a;
}
.btn-soft-danger {
    color: #e7515a !important;
    background-color: rgba(231, 81, 91, 0.18);
    border-color:rgba(231, 81, 91, 0.18);
}
.btn-danger:hover,
.btn-danger:focus {
    color: #fff !important;
    background-color: #e7515a;
    box-shadow: none;
    border-color: #e7515a;
}
.btn-danger:active,
.btn-danger.active {
    background-color: #e7515a;
    border-top: 1px solid #e7515a;
}
.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show>.btn-danger.dropdown-toggle {
    color: #fff !important;
    background-color: #e7515a;
    border-color: #e7515a;
}
.btn-danger.disabled,
.btn-danger.btn[disabled],
.btn-danger:disabled {
    background-color: #e7515a;
    border-color: #e7515a;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.btn-danger.active.focus, .btn-danger.active:focus, .btn-danger.active:hover, .btn-danger.focus:active, .btn-danger:active:focus,
.btn-danger:active:hover, .open > .dropdown-toggle.btn-danger.focus, .open > .dropdown-toggle.btn-danger:focus, .open > .dropdown-toggle.btn-danger:hover {
    color: #fff !important;
    background-color: #c00;
    border-color: #c00
}
.btn-danger .caret { border-top-color: #fff }
.btn-group.open .btn-danger.dropdown-toggle { background-color: #a9302a }
.btn-dark {
    color: #fff !important;
    background-color: #3b3f5c;
    border-color: #3b3f5c;
}
.btn-soft-dark {
    color: #3b3f5c !important;
    background-color: rgba(59, 63, 92, 0.18);
    border-color: rgba(59, 63, 92, 0.18);
}
.btn-dark:hover,
.btn-dark:focus {
    color: #fff !important;
    background-color: #3b3f5c;
    box-shadow: none;
    border-color: #3b3f5c;
}
.btn-dark:active,
.btn-dark.active {
    background-color: #3b3f5c;
    border-top: 1px solid #3b3f5c
}
.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show>.btn-dark.dropdown-toggle {
    color: #fff !important;
    background-color: #3b3f5c;
    border-color: #3b3f5c;
}
.btn-dark.disabled,
.btn-dark.btn[disabled],
.btn-dark:disabled {
    background-color: #3b3f5c;
    border-color: #3b3f5c;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.btn-dark .caret { border-top-color: #fff }
.btn-group.open .btn-dark.dropdown-toggle { background-color: #484848 }
.btn-success {
    color: #fff !important;
    background-color: #8dbf42;
    border-color: #8dbf42
}
.btn-soft-success {
    color: #8dbf42 !important;
    background-color: rgba(141, 191, 66, 0.18);
    border-color: rgba(141, 191, 66, 0.18);
}
.btn-success:hover,
.btn-success:focus {
    background: rgb(0,217,255);
    background: linear-gradient(90deg, rgba(0,217,255,1) 2%, rgba(36,206,38,0.958420868347339) 77%);
    color: #fff;
    font-weight: 700 !important;
    border-radius: 30px;
    font-weight: 500;
    border: none;
}
.btn-success:active,
.btn-success.active {
    background-color: #8dbf42;
    border-top: 1px solid #8dbf42
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle {
    color: #fff !important;
    background-color: #8dbf42;
    border-color: #8dbf42;
}
.btn-success.disabled,
.btn-success.btn[disabled],
.btn-success:disabled {
    background-color: #8dbf42;
    border-color: #8dbf42;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.btn-success.active.focus, .btn-success.active:focus, .btn-success.active:hover, .btn-success.focus:active, .btn-success:active:focus,
.btn-success:active:hover, .open > .dropdown-toggle.btn-success.focus, .open > .dropdown-toggle.btn-success:focus, .open > .dropdown-toggle.btn-success:hover {
    color: #fff !important;
    background-color: #17c678;
    border-color: #17c678
}
.btn-success .caret { border-top-color: #fff }
/*-----/Button Light Colors------*/
.btn.box-shadow-none { border: none; }
.btn.box-shadow-none:hover, .btn.box-shadow-none:focus {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
}
.box-shadow-none {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.btn.box-shadow-none:not(:disabled):not(.disabled).active, 
.btn.box-shadow-none:not(:disabled):not(.disabled):active, 
.show>.btn.box-shadow-none.dropdown-toggle {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
}
.btn-group.open .btn-success.dropdown-toggle { background-color: #499249 }
.btn-dismiss {
    color: #0e1726;
    background-color: #fff !important;
    border-color: #fff;
    padding: 3px 7px;
}
.btn-dismiss:hover,
.btn-dismiss:focus {
    color: #0e1726;
    background-color: #fff;
}
.btn-dismiss:active,
.btn-dismiss.active {
    background-color: #fff;
    border-top: 1px solid #fff
}
.btn-group>.btn i {
    margin-right: 3px
}
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    -webkit-box-shadow: 0 0px 0px 0 rgba(0,0,0,.16),0 0px 0px 0 rgba(0,0,0,.12);
    -moz-box-shadow: 0 0px 0px 0 rgba(0,0,0,.16),0 0px 0px 0 rgba(0,0,0,.12);
    box-shadow: 0 0px 0px 0 rgba(0,0,0,.16),0 0px 0px 0 rgba(0,0,0,.12)
}
.btn-group>.btn+.dropdown-toggle {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
/*
    Btn group dropdown-toggle
*/
.btn-group>.btn+.dropdown-toggle.btn-primary {
    border-left: 1px solid rgb(68, 104, 253);
}
.btn-group>.btn+.dropdown-toggle.btn-success {
    border-left: 1px solid rgb(163, 198, 111);
}
.btn-group>.btn+.dropdown-toggle.btn-info {
    border-left: 1px solid rgb(73, 172, 251);
}
.btn-group>.btn+.dropdown-toggle.btn-warning {
    border-left: 1px solid rgb(245, 180, 85);
}
.btn-group>.btn+.dropdown-toggle.btn-danger {
    border-left: 1px solid rgb(241, 132, 139);
}
.btn-group>.btn+.dropdown-toggle.btn-dark {
    border-left: 1px solid rgb(112, 118, 122);
}
.btn-group>.btn+.dropdown-toggle.btn-secondary {
    border-left: 1px solid rgb(131, 83, 220);
}
.btn-group.dropleft .dropdown-toggle-split {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.btn-group.dropleft .btn-primary:not(.dropdown-toggle-split) {
    border-left: 1px solid rgb(68, 104, 253);
}
.btn-group.dropleft .btn-success:not(.dropdown-toggle-split) {
    border-left: 1px solid rgb(163, 198, 111);
}
.btn-group.dropleft .btn-info:not(.dropdown-toggle-split) {
    border-left: 1px solid rgb(73, 172, 251);
}
.btn-group.dropleft .btn-warning:not(.dropdown-toggle-split) {
    border-left: 1px solid rgb(245, 180, 85);
}
.btn-group.dropleft .btn-danger:not(.dropdown-toggle-split) {
    border-left: 1px solid rgb(241, 132, 139);
}
.btn-group.dropleft .btn-dark:not(.dropdown-toggle-split) {
    border-left: 1px solid rgb(112, 118, 122);
}
.btn-group.dropleft .btn-secondary:not(.dropdown-toggle-split) {
    border-left: 1px solid rgb(131, 83, 220);
}
.btn .badge.badge-align-right {
    position: absolute;
    top: -1px;
    right: 8px;
}
.dropup .btn .caret {
    border-bottom-color: #0e1726
}
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {
    background-color: #2f44b2;
    color: #fff!important;
}
.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show>.btn-outline-success.dropdown-toggle {
    background-color: #8dbf42;
    color: #fff!important;
}
.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show>.btn-outline-info.dropdown-toggle {
    background-color: #2196f3;
    color: #fff!important;
}
.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show>.btn-outline-danger.dropdown-toggle {
    background-color: #e7515a;
    color: #fff!important;
}
.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show>.btn-outline-warning.dropdown-toggle {
    background-color: #e2a03f;
    color: #fff!important;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show>.btn-outline-secondary.dropdown-toggle {
    background-color: #5c1ac3;
    color: #fff!important;
}
.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show>.btn-outline-dark.dropdown-toggle {
    background-color: #3b3f5c;
    color: #fff!important;
}
.show>.btn-outline-primary.dropdown-toggle:after, .show>.btn-outline-success.dropdown-toggle:after, .show>.btn-outline-info.dropdown-toggle:after, .show>.btn-outline-danger.dropdown-toggle:after, .show>.btn-outline-warning.dropdown-toggle:after, .show>.btn-outline-secondary.dropdown-toggle:after, .show>.btn-outline-dark.dropdown-toggle:after {
    color: #fff!important;
}
.show>.btn-outline-primary.dropdown-toggle:before, .show>.btn-outline-success.dropdown-toggle:before, .show>.btn-outline-info.dropdown-toggle:before, .show>.btn-outline-danger.dropdown-toggle:before, .show>.btn-outline-warning.dropdown-toggle:before, .show>.btn-outline-secondary.dropdown-toggle:before, .show>.btn-outline-dark.dropdown-toggle:before {
    color: #fff!important;
}
.btn-outline-primary {
    border: 1px solid #2f44b2 !important;
    color: #2f44b2 !important;
    background-color: transparent;
    box-shadow: none;
}
.btn-outline-info {
    border: 1px solid #2196f3 !important;
    color: #2196f3 !important;
    background-color: transparent;
    box-shadow: none;
}
.btn-outline-warning {
    border: 1px solid #e2a03f !important;
    color: #e2a03f !important;
    background-color: transparent;
    box-shadow: none;
}
.btn-outline-success {
    border: 1px solid #8dbf42 !important;
    color: #8dbf42 !important;
    background-color: transparent;
    box-shadow: none;
}
.btn-outline-danger {
    border: 1px solid #e7515a !important;
    color: #e7515a !important;
    background-color: transparent;
    box-shadow: none;
}
.btn-outline-secondary {
    border: 1px solid #5c1ac3 !important;
    color: #5c1ac3 !important;
    background-color: transparent;
    box-shadow: none;
}
.btn-outline-dark {
    border: 1px solid #3b3f5c !important;
    color: #3b3f5c !important;
    background-color: transparent;
    box-shadow: none;
}
.btn-outline-primary:hover,
.btn-outline-info:hover,
.btn-outline-warning:hover,
.btn-outline-success:hover,
.btn-outline-danger:hover,
.btn-outline-secondary:hover,
.btn-outline-dark:hover {
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1)
}
.btn-outline-primary:hover {
    color: #fff !important;
    background-color: #2f44b2;
}
.btn-outline-info:hover {
    color: #fff !important;
    background-color: #2196f3
}
.btn-outline-warning:hover {
    color: #fff !important;
    background-color: #e2a03f;
}
.btn-outline-success:hover {
    color: #fff !important;
    background-color: #8dbf42;
}
.btn-outline-danger:hover {
    color: #fff !important;
    background-color: #e7515a;
} 
.btn-outline-secondary:hover {
    color: #fff !important;
    background-color: #5c1ac3;
}
.btn-outline-dark:hover {
    color: #fff !important;
    background-color: #3b3f5c
}
/*      Dropdown Toggle       */
.btn-rounded {
    -webkit-border-radius: 1.875rem !important;
    -moz-border-radius: 1.875rem !important;
    -ms-border-radius: 1.875rem !important;
    -o-border-radius: 1.875rem !important;
    border-radius: 1.875rem !important;
}
/* RIPPLE BUTTON */
.ripple-button {
    -webkit-appearance: none;
    position: relative;
    display: inline-block;
    padding: 0.4375rem 1.25rem;
    margin: 0;
    vertical-align: middle;
    overflow: visible;
    color: #fff;
    line-height: 20px;
    font-weight: 400;
    text-align: center;
    background: transparent;
    border: 0;
    border-radius: .2rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    color: #fff;
}
.ripple-button:hover, .ripple-button:focus {
    outline: 0;
    text-decoration: none;
}
.ripple-button:not(:disabled) {
    cursor: pointer;
}
.ripple-button-primary {
    background: var(--thm-green);
/*    border:1px solid #1d377f;*/
/*    text-shadow: 2px 2px 0 #2f44b2;*/
}
.ripple-button-primary:hover {
    opacity: 0.9;
}
.ripple-button-success {
    background: #8dbf42;
    text-shadow: 2px 2px 0 #8dbf42;
}
.ripple-button-success:hover {
    opacity: 0.9;
}
.ripple-button-info {
    background: #2196f3;
    text-shadow: 2px 2px 0 #2196f3;
}
.ripple-button-info:hover {
    opacity: 0.9;
}
.ripple-button-warning {
    background: #e2a03f;
    text-shadow: 2px 2px 0 #e2a03f;
}
.ripple-button-warning:hover {
    opacity: 0.9;
}
.ripple-button-danger {
    background: #e7515a;
    text-shadow: 2px 2px 0 #e7515a;
}
.ripple-button-danger:hover {
    opacity: 0.9;
}
.ripple-button-dark {
    background: #3b3f5c;
    text-shadow: 2px 2px 0 #3b3f5c;
}
.ripple-button-danger:hover {
    opacity: 0.9;
}
.ripple-button-secondary {
    background: #5c1ac3;
    text-shadow: 2px 2px 0 #5c1ac3;
}
.ripple-button-secondary:hover {
    opacity: 0.9;
}
.ripple-button--blue {
    background: #4A89DC;
    text-shadow: 2px 2px 0 #276ecc;
}
.ripple-button--blue:hover {
    background: #357bd8;
}
.ripple-button--red {
    background: #E9573F;
    text-shadow: 2px 2px 0 #dc3519;
}
.ripple-button--red:hover {
    background: #e64328;
}
.ripple-ripple {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: transparent;
}
.ripple-ripple__circle {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    width: 0;
    height: 0;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.25);
}
.ripple-ripple.is-active .ripple-ripple__circle {
    -webkit-animation: a-ripple .4s ease-in;
    animation: a-ripple .4s ease-in;
}
@-webkit-keyframes a-ripple {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    100% {
        width: 200%;
        padding-bottom: 200%;
        opacity: 0;
    }
}
@keyframes a-ripple {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    100% {
        width: 200%;
        padding-bottom: 200%;
        opacity: 0;
    }
}
.data-marker {
    padding: 2px;
    border-radius: 50%;
    font-size: 18px;
    display: inline-flex;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}
.data-marker-success { background-color: #8dbf42; }
.data-marker-warning { background-color: #e2a03f; }
.data-marker-danger { background-color: #e7515a; }
.data-marker-info { background-color: #e7515a; }
.data-marker-dark { background-color: #e7515a; }
/* .badge {
    font-weight: 600;
    line-height: 1.4;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 600;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
}
.badge:hover {
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
} */
.badge.badge-enabled { background-color: #8dbf42; color: #fff; }
.badge.badge-disable { background-color: #e7515a; color: #fff; }
.badge-rounded { border-radius: 30px; }
.badge-collapsed-img img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.3);
    margin-left: -21px;
}
.badge-collapsed-img.badge-tooltip img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.3);
    margin-left: -21px;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
}
.badge-collapsed-img.badge-tooltip img:hover {
    -webkit-transform: translateY(-5px) scale(1.02);
    transform: translateY(-5px) scale(1.02);
}
.badge-collapsed-img.translateY-axis img {
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
}
.badge-collapsed-img.translateY-axis img:hover {
    -webkit-transform: translateY(-5px) scale(1.02);
    transform: translateY(-5px) scale(1.02);
}
.badge-collapsed-img.rectangle-collapsed img {
    width: 45px;
    height: 32px;
}
.badge-collapsed-img.translateX-axis img {
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
}
.badge-collapsed-img.translateX-axis img:hover {
    -webkit-transform: translateX(5px) scale(1.02);
    transform: translateX(5px) scale(1.02);
}
.badge-primary { color: #fff; background-color: #2f44b2; }
.badge-info { color: #fff; background-color: #2196f3; }
.badge-success { color: #fff; background-color: #8dbf42; }
.badge-success-teal { color: #fff; background-color: #009688; }
.badge-danger { color: #fff; background-color: #e7515a; }
.badge-warning { color: #fff; background-color: #e2a03f; }
.badge-dark { color: #fff; background-color: #3b3f5c; }
.badge-secondary { background-color: #5c1ac3; }
.outline-badge-primary {
    color: #2f44b2;
    background-color: transparent;
    border: 1px solid #2f44b2;
}
.outline-badge-info {
    color: #2196f3;
    background-color: transparent;
    border: 1px solid #2196f3;
}
.outline-badge-success {
    color: #8dbf42;
    background-color: transparent;
    border: 1px solid #8dbf42;
}
.outline-badge-success-teal {
    color: #009688;
    background-color: transparent;
    border: 1px solid #009688;
}
.outline-badge-danger {
    color: #e7515a;
    background-color: transparent;
    border: 1px solid #e7515a;
}
.outline-badge-warning {
    color: #e2a03f;
    background-color: transparent;
    border: 1px solid #e2a03f;
}
.outline-badge-dark {
    color: #3b3f5c;
    background-color: transparent;
    border: 1px solid #3b3f5c;
}
.outline-badge-secondary {
    color: #5c1ac3;
    background-color: transparent;
    border: 1px solid #5c1ac3;
}
.outline-badge-primary:focus, .outline-badge-primary:hover {
    color: #2f44b2;
}
.outline-badge-secondary:focus, .outline-badge-secondary:hover {
    color: #5c1ac3;
}
.outline-badge-success:focus, .outline-badge-success:hover {
    color: #8dbf42;
}
.outline-badge-success-teal:focus, .outline-badge-success-teal:hover {
    color: #009688;
}
.outline-badge-danger:focus, .outline-badge-danger:hover {
    color: #e7515a;
}
.outline-badge-warning:focus, .outline-badge-warning:hover {
    color: #e2a03f;
}
.outline-badge-info:focus, .outline-badge-info:hover {
    color: #2196f3;
}
.outline-badge-dark:focus, .outline-badge-dark:hover {
    color: #3b3f5c;
}
.badge[class*="link-badge-"] { cursor: pointer; }
.link-badge-primary {
    color: #2f44b2;
    background-color: transparent;
    border: 1px solid transparent;
}
.link-badge-info {
    color: #2196f3;
    background-color: transparent;
    border: 1px solid transparent;
}
.link-badge-success {
    color: #8dbf42;
    background-color: transparent;
    border: 1px solid transparent;
}
.link-badge-success-teal {
    color: #009688;
    background-color: transparent;
    border: 1px solid transparent;
}
.link-badge-danger {
    color: #e7515a;
    background-color: transparent;
    border: 1px solid transparent;
}
.link-badge-warning {
    color: #e2a03f;
    background-color: transparent;
    border: 1px solid transparent;
}
.link-badge-dark {
    color: #3b3f5c;
    background-color: transparent;
    border: 1px solid transparent;
}
.link-badge-secondary {
    color: #5c1ac3;
    background-color: transparent;
    border: 1px solid transparent;
}
.link-badge-primary:focus, .link-badge-primary:hover {
    color: #2f44b2;
    background-color: transparent;
}
.link-badge-secondary:focus, .link-badge-secondary:hover {
    color: #6f51ea;
    background-color: transparent;
}
.link-badge-success:focus, .link-badge-success:hover {
    color: #2ea37d;
    background-color: transparent;
}
.link-badge-success-teal:focus, .link-badge-success-teal:hover {
    color: #009688;
    background-color: transparent;
}
.link-badge-danger:focus, .link-badge-danger:hover {
    color: #e7515a;
    background-color: transparent;
}
.link-badge-warning:focus, .link-badge-warning:hover {
    color: #dea82a;
    background-color: transparent;
}
.link-badge-info:focus, .link-badge-info:hover {
    color: #009eda;
    background-color: transparent;
}
.link-badge-dark:focus, .link-badge-dark:hover {
    color: #454656;
    background-color: transparent;
}
/* Custom Dropdown*/
.custom-dropdown .dropdown-toggle::after,
.custom-dropdown-icon .dropdown-toggle::after { display: none  }
.custom-dropdown .dropdown-toggle::before,
.custom-dropdown-icon .dropdown-toggle::before { display: none  }
.custom-dropdown .dropdown-menu,
.custom-dropdown-icon .dropdown-menu {
    min-width: 11rem;
    border-radius: 4px;
    border: none;
    border: 1px solid #e0e6ed;
    z-index: 899;
    box-shadow: rgba(113, 106, 202, 0.2) 0px 0px 15px 1px;
    top: 15px !important;
    padding: 10px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
}
.custom-dropdown .dropdown-item.active,
.custom-dropdown .dropdown-item:active,
.custom-dropdown .dropdown-item:hover,
.custom-dropdown-icon .dropdown-item.active,
.custom-dropdown-icon .dropdown-item:active,
.custom-dropdown-icon .dropdown-item:hover {
    color: #2f44b2;
    background-color: #f7f9ff;
    border-radius: 6px;
}
.custom-dropdown .dropdown-item,
.custom-dropdown-icon .dropdown-item {
    font-size: 13px;
    color: #888ea8;
    display: block;
    font-weight: 700;
    padding: 11px 8px;
    font-size: 12px;
}
.custom-dropdown-icon .dropdown-menu .dropdown-item svg {
    width: 20px;
    height: 20px;
    margin-right: 3px;
    color: #888ea8;
}
.custom-dropdown .dropdown-item.active svg,
.custom-dropdown .dropdown-item:active svg,
.custom-dropdown .dropdown-item:hover svg,
.custom-dropdown-icon .dropdown-item.active svg,
.custom-dropdown-icon .dropdown-item:active svg,
.custom-dropdown-icon .dropdown-item:hover svg { color: #2f44b2; }
.status.rounded-tooltip .tooltip-inner { border-radius: 20px; padding: 8px 20px; }
.tooltip-inner {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}
.popover {
    z-index: 999;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-bottom-color: #b3b3b3
}
input[disabled], select[disabled], textarea[disabled],
input[readonly], select[readonly], textarea[readonly] {
    cursor: not-allowed;
    background-color: #f1f2f3!important;
    color: #d1d1d1
}
.help-block, .help-inline { color: #555555 }
.controls { position: relative }
.search-form-control { border-radius: .25rem; }
/*  Table   */
/* .table-bordered { border: 1px solid #f1f2f3; }
.table-striped tbody tr:nth-of-type(odd) { background-color: #f1f2f3 !important; }
.table > tbody > tr > td { 
    vertical-align: middle;
    color: #515365;
    font-size: 13px;
    letter-spacing: 1px; }
.table > thead > tr > th {
    color: #2f44b2;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase; }
.table > tbody > tr > td .usr-img-frame {
    background-color: #ebedf2;
    padding: 2px;
    width: 35px;
    height: 35px;
}
.table > tbody > tr > td .usr-img-frame img { width: 35px; margin: 0; }
.table > tbody > tr > td .admin-name {
    font-weight: 700;
    color: #515365;
}
.table > tbody > tr > td .progress { width: 135px; height: 6px; margin: auto 0; }
.table > tbody > tr > td svg.icon { width: 21px; }
.table > tbody > tr > td .t-dot {
    background-color: #000;
    height: 11px;
    width: 11px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 auto;
}
.table > tbody > tr > td svg.t-icon {
    padding: 5px;
    border-radius: 50%;
    font-size: 11px;
    vertical-align: sub;
    cursor: pointer;
}
.table > tbody > tr > td svg.t-icon.t-hover-icon:hover { background-color: #e7515a; color: #fff; }
.table-bordered td { border: 1px solid #ebedf2; }
.table-bordered th { border: 1px solid #ebedf2; }
.table thead th { vertical-align: bottom; border-bottom: none; }
.table-hover:not(.table-dark) tbody tr:hover { background-color: #f1f2f3!important; }
.table-controls>li>a svg { color: #25d5e4; }
.table tr td .custom-dropdown.t-custom-dropdown a.dropdown-toggle,
.table tr td .custom-dropdown-icon.t-custom-dropdown a.dropdown-toggle {
    border-radius: 5px;
    border: 1px solid #d3d3d3;
}
.table-controls>li>a svg { color: #888ea8; width: 21px; } */
/*  Table Dark      */
/* .table.table-dark > thead > tr > th { color: #d3d3d3; }
.table.table-dark > tbody > tr > td { color: #ffffff; }
.table-dark { background-color: #060818; }
.table-dark.table-hover tbody tr { background-color: #060818; }
.table-dark td, .table-dark th, .table-dark thead th { border-color: #191e3a!important; }
.table-dark.table-hover tbody tr:hover { background-color: rgba(25, 30, 58, 0.6313725490196078); }
.table.table-dark > tbody > tr > td i.t-icon {
    padding: 5px;
    border-radius: 50%;
    font-size: 14px;
    vertical-align: sub;
    cursor: pointer;
    color: #0e1726!important;
}
table .badge-success,table .badge-primary, table .badge-warning,table .badge-danger,
table .badge-info, table .badge-secondary, table .badge-dark  {
    will-change: opacity, transform;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
}
.table > tfoot > tr > th { color: #3b3f5c; }
.table-vertical-align tr, .table-vertical-align th, .table-vertical-align td { vertical-align: middle!important } */
.statbox .widget-content:before,
.statbox .widget-content:after {
    display: table;
    content: "";
    line-height: 0;
    clear: both
}
.nav-tabs>li>a { -webkit-border-radius: 0!important; -moz-border-radius: 0!important; border-radius: 0!important }
.btn-toolbar { margin-left: 0px; }
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { .input-group>.form-control { flex: 1 1 auto; width: 1%; } }
.spin { -webkit-animation: spin 2s infinite linear; animation: spin 2s infinite linear; }
.slow-spin { -webkit-animation: spin 20s infinite linear; animation: spin 20s infinite linear; }
@keyframes spin{
    0%{ -webkit-transform:rotate(0deg); transform:rotate(0deg) }
    100%{ -webkit-transform:rotate(359deg); transform:rotate(359deg) }
}
@-webkit-keyframes spin{
    0%{ -webkit-transform:rotate(0deg); transform:rotate(0deg) }
    100%{ -webkit-transform:rotate(359deg); transform:rotate(359deg) }
}
.toast-primary {
    background: #2f44b2;
}
.toast-header {
    background: #2f44b2;
    color: #fff;
    border-bottom: 1px solid rgba(33, 150, 243, 0.3411764705882353);
}
.toast-header .meta-time {
    color: #f1f2f3;
}
.toast-header .close {
    color: #f1f2f3;
    opacity: 1;
    text-shadow: none;
}
.toast-body {
    padding: 16px 12px;
    color: #fff;
}
.bg-primary {
    /* background-color: #2f44b2!important;
    border-color: #2f44b2; */
    color: #fff
}
.bg-success {
    background-color: #8dbf42!important;
    border-color: #8dbf42;
    color: #fff
}
.bg-success-teal {
    background-color: #009688!important;
    border-color: #009688;
    color: #fff
}
.bg-info {
    background-color: #2196f3!important;
    border-color: #2196f3;
    color: #fff
}
/* .bg-warning {
    background-color: #e2a03f!important;
    border-color: #e2a03f;
    color: #fff
}
.bg-danger {
    background-color: #e7515a!important;
    border-color: #e7515a;
    color: #fff
} */
.bg-secondary {
    background-color: #2262c6!important;
    border-color: #2262c6;
    color: #fff;
}
.bg-dark {
    background-color: #fff;
    border-color: #3b3f5c;
    color: #fff
}
.bg-facebook { 
    background-color: #4867aa !important; 
    border-color: #4867aa !important;
    color: #ffffff;
}
.bg-linkedin { 
    background-color: #0077b5 !important; 
    border-color: #0077b5 !important;
    color: #ffffff;
}
/* Light Background */
.bg-light-primary {
    background-color: #e5eaff!important;
    border-color: #e5eaff;
    color: #2f44b2;
}
.bg-light-success {
    background-color: #e6ffbf!important;
    border-color: #e6ffbf;
    color: #8dbf42
}
.bg-light-info {
    background-color: #bae7ff!important;
    border-color: #bae7ff;
    color: #2196f3
}
.bg-light-warning {
    background-color: #ffeccb!important;
    border-color: #ffeccb;
    color: #e2a03f
}
.bg-light-danger {
    background-color: #ffe1e2!important;
    border-color: #ffe1e2;
    color: #e7515a
}
.bg-light-secondary {
    background-color: #dccff7!important;
    border-color: #dccff7;
    color: #5c1ac3;
}
.bg-light-dark {
    background-color: #acb0c3;
    border-color: #acb0c3;
    color: #fff
}
/* Progress Bar */
.progress {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-color: #ebedf2;
    margin-bottom: 1.25rem;
    height: 16px;
    box-shadow: 1px 3px 20px 3px #f1f2f3;
}
.progress.progress-bar-stack .progress-bar:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}
.progress .progress-bar {
    font-size: 10px;
    font-weight: 700;
    box-shadow: 0 2px 4px rgba(0, 69, 255, 0.15), 0 8px 16px rgba(0, 69, 255, 0.2);
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 100;
}
.progress:not(.progress-bar-stack) .progress-bar { border-radius: 16px; }
.progress-sm { height: 4px; }
.progress-md { height: 10px; }
.progress-lg { height: 20px; }
.progress-xl { height: 25px; }
.progress-striped .progress-bar {
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}
.progress .progress-title {
    display: flex;
    justify-content: space-between;
    padding: 15px;
}
.progress .progress-title span { align-self: center; }
.progress .progress-bar.bg-gradient-primary {
    background-color: #2f44b2;
    background: linear-gradient(to right, #a2aee8 0%, #2f44b2 100%);
}
.progress .progress-bar.bg-gradient-info {
    background-color: #2f44b2;
    background-image: linear-gradient(to right, #04befe 0%, #4481eb 100%);
}
.progress .progress-bar.bg-gradient-success {
    background-color: #2f44b2;
    background-image: linear-gradient(to right, #3cba92 0%, #0ba360 100%);
}
.progress .progress-bar.bg-gradient-warning {
    background-color: #2f44b2;
    background-image: linear-gradient(to right, #f09819 0%, #ff5858 100%);
}
.progress .progress-bar.bg-gradient-secondary {
    background-color: #2f44b2;
    background-image: linear-gradient(to right, #7579ff 0%, #b224ef 100%);
}
.progress .progress-bar.bg-gradient-danger {
    background-color: #2f44b2;
    background-image: linear-gradient(to right, #d09693 0%, #c71d6f 100%);
}
.progress .progress-bar.bg-gradient-dark {
    background-color: #2f44b2;
    background-image: linear-gradient(to right, #2b5876 0%, #4e4376 100%);
}
.progress-vertical {
    min-height: 250px;
    height: 250px;
    width: 10px;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 20px;
}
.progress-vertical-bottom {
    min-height: 250px;
    height: 250px;
    position: relative;
    width: 10px;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 20px;
}
.br-0 { border-radius: 0 !important; }
.br-4 { border-radius: 4px!important; }
.br-6 { border-radius: 6px!important; }
.br-30 { border-radius: 30px!important; }
.br-50 { border-radius: 50px !important; }
.br-left-30 { border-top-left-radius: 30px!important; border-bottom-left-radius: 30px!important; }
.br-right-30 { border-top-right-radius: 30px!important; border-bottom-right-radius: 30px!important; }
.bx-top-6 { border-top-right-radius: 6px!important; border-top-left-radius: 6px!important; }
.bx-bottom-6 { border-bottom-right-radius: 6px!important; border-bottom-left-radius: 6px!important;    }
/* Text-colors */
.text-primary { color: var(--thm-green) !important; }
.text-success { color: var(--thm-green) !important; }
.text-success-teal { color: #009688 !important; }
.text-info { color: #2196f3 !important; }
.text-light-danger { color: #ffd2d2 !important; }
.text-danger { color: #e7515a !important; }
.text-warning { color: #e2a03f !important;}
.text-secondary { color: #5c1ac3 !important; }
.text-dark { color: #333 !important; }
.text-muted { color: #888ea8 !important }
.text-white { color: #fff !important }
.text-light-black { color: #0e1726 !important }
.text-black { color: #000 !important }
.text-facebook { color: #4867aa !important; }
.text-linkedin { color: #0077b5 !important; }
/* Background Colors */
.background-primary { background-color: #2f44b2 !important; }
.background-success { background-color: #8dbf42 !important; }
.background-success-teal { background-color: #009688 !important; }
.background-info { background-color: #2196f3 !important; }
.background-danger { background-color: #e7515a !important; }
.background-warning { background-color: #e2a03f !important;}
.background-secondary { background-color: #5c1ac3 !important; }
.background-dark { background-color: #3b3f5c !important; }
.background-muted { background-color: #888ea8 !important }
.background-white { background-color: #fff !important }
.background-light-black { background-color: #0e1726 !important }
.background-black { background-color: #000 !important }
.bg-gradient-primary {
    background-color: #2f44b2 !important;
    background: linear-gradient(to right, #7988d1 0%, #2f44b2 100%);
    border: 0px;
}
.bg-gradient-info {
    background-color: #2f44b2 !important;
    background-image: linear-gradient(to right, #04befe 0%, #4481eb 100%) !important;
    border: 0px;
}
.bg-gradient-success {
    background-color: #2f44b2 !important;
    background-image: linear-gradient(to right, #3cba92 0%, #0ba360 100%) !important;
    border: 0px;
}
.bg-gradient-warning {
    background-color: #2f44b2 !important;
    background-image: linear-gradient(to right, #f09819 0%, #ff5858 100%) !important;
    border: 0px;
}
.bg-gradient-secondary {
    background-color: #2f44b2 !important;
    background-image: linear-gradient(to right, #7579ff 0%, #b224ef 100%) !important;
    border: 0px;
}
.bg-gradient-danger {
    background-color: #2f44b2 !important;
    background-image: linear-gradient(to right, #d09693 0%, #c71d6f 100%) !important;
    border: 0px;
}
.bg-gradient-dark {
    background-color: #2f44b2 !important;
    background-image: linear-gradient(to right, #2b5876 0%, #4e4376 100%) !important;
    border: 0px;
}
a.text-primary:focus, 
a.text-primary:hover {
    color: var(--thm-blue) !important;
}
a.text-success:focus, a.text-success:hover {
    color: var(--thm-blue)!important;
}
/* Border Main */
.border { border: 1px solid!important }
.border-bottom { border-bottom: 1px solid!important }
.border-top { border-top: 1px solid!important }
.border-right { border-right: 1px solid!important }
.border-left { border-left: 1px solid!important }
.border-primary { border-color: #2f44b2!important }
.border-info { border-color: #2196f3!important }
.border-warning { border-color: #e2a03f!important }
.border-success { border-color: #8dbf42!important }
.border-danger { border-color: #e7515a!important }
.border-secondary { border-color: #5c1ac3!important }
.border-light { border-color: #dee2e6 !important }
.border-dark { border-color: #3b3f5c!important }
/* Border Style */
.border-dotted { border-style: dotted!important; }
.border-dashed { border-style: dashed!important; }
.border-solid { border-style: solid!important; }
.border-double { border-style: double!important; }
/* Border Width */
.border-width-1px { border-width: 1px!important; }
.border-width-2px { border-width: 2px!important; }
.border-width-3px { border-width: 3px!important; }
.border-width-4px { border-width: 4px!important; }
.border-width-5px { border-width: 5px!important; }
.border-width-6px { border-width: 6px!important; }
/* Transform Position */
.position-absolute { position:absolute; }
.position-static { position:static; }
.position-fixed { position:fixed; }
.position-inherit { position:inherit; }
.position-initial { position:initial; }
.position-relative { position:relative; }


.btn-primary{
    background: linear-gradient(90deg, rgba(0,217,255,1) 2%, rgba(36,206,38,0.958420868347339) 77%);
    /* border: var(--thm-green)!important; */
}
.btn-outline-primary{
    background-color: #fff!important;
    border: 1px solid var(--thm-green)!important;
    color: var(--thm-green)!important;
}
.btn-outline-primary:hover{
    background-color: var(--thm-blue)!important;
    border: 1px solid  var(--thm-blue)!important; 
}

.btn-yellow{
    background: linear-gradient(to right, #ff00ca 0%, #ffff00 100%);
}
.btn-yellow:hover{
    background: linear-gradient(to left, #ff00ca 0%, #ffff00 100%);
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #18df6b;
    border-color: #a7c4e6;
}
.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #323a46;
    background-color: #c6e0ff;
    border: 1px solid #f5f3f3;
}