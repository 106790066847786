html, body {
  width: 100%;
/*  overflow-x: hidden; */
}
:root{
  --thm-blue :#001655;
  --thm-green:#68b90b;
  --thm-yellow:#ffff00;
  --thm-orange:#ffa300;
}

body {
  margin:0px;
  padding:0px;
  font-family: 'Poppins', sans-serif;
  font-weight: 300; 
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
 font-family: 'Poppins', sans-serif;
  margin-top: 0;
  width: max-content;
  width: fit-content;
}

a {
  color: var(--thm-green);
  transition: all .3s ease-in-out; }

a:focus,
button:focus {
  outline: none; }

.btn:hover,
a:hover {
  text-decoration: none;
  color: #0bd7cc; }

img {
  max-width: 100%; }

.bg-fixed {
  background-size: cover; }

.bg-grey {
  background-color: #f7f8f9; }

.fs-40 {
  font-size: 40px; }
  .navbar{
    background: inherit!important;
  }
section {
  position: relative; }

.section-content {
  padding-top: 40px;
  padding-bottom: 40px; }

.title-wrap,
.section-title {
  color: #001655!important;
  text-align: center; }

.shadow {
  box-shadow: 0 10px 30px 0 rgba(67, 80, 98, 0.24), 0 2px 4px 0 rgba(67, 80, 98, 0.1) !important; }

#header-navbar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 11;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86); }

.navbar-brand {
  font-size: 40px;
  color: #000; }

.navbar-nav .nav-link {
  color: #fff!important;
  font-weight: 300!important;
  transition: .3s ease; 
  margin-right: 8px;
  font-size: 16px!important;
  position: relative!important;
  letter-spacing: inherit;
}
.navbar-nav .nav-link:before {
  content: '';
  width: 5px;
  height: 5px;
  display: inline-block;
  background-color: #fff;
  border-radius: 100%;
  position: relative;
  top:-4px;
  margin-right: 10px;
}
.navbar-nav:hover li a {
  opacity: 0.5; 
}
  .navbar-nav:hover li a:hover {
    opacity: 1; 
  }
  .navbar-nav li a:after {
    width: 0%;
    height: 3px;
    overflow: hidden;
    display: block;
    content: '';
    background-image: linear-gradient(to right, #82ff00, #efff00, #ffd500, #ff8e00);
    position: absolute;
    bottom:0px;
    left:10px;
    border-radius: inherit;
    -webkit-transition:all .4s cubic-bezier(0.68, -0.55, 0.265, 1.55); 
    -o-transition:all .4s cubic-bezier(0.68, -0.55, 0.265, 1.55); 
    transition:all .4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .navbar-nav li:hover a:after {
  width: 95%;
  }
.navbar-toggler {
  font-size: 2rem;
  color: #fff; }

.jumbotron {
  color: #fff;
  padding-top: 80px;
  padding-bottom: 80px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  border-radius: 0;
  margin-bottom: 0;
  overflow: hidden;
  background-image: url('body-bg.jpg');
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh; 
  }
.btn-getstarted,.btn-features,.btn-buynow{
  padding:10px 30px;
  border-radius: 50px;
  color: #fff;
  font-weight: 500;
  position: relative;
  top:20px;
  font-size: 16px;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 3px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #90ff00, #cfc300, #fd9700);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #001655 inset;
  position: relative;
  top:150px;
}
.btn-getstarted:hover,.btn-features:hover,.btn-buynow:hover{
   background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #fd9700, #cfc300, #90ff00);
   color: #fff000;
}
.btn-features{
  position: inherit!important;
  top:0px!important;
  font-size: 15px;
  padding:8px 25px;
}
.btn-buynow{
   position: inherit!important;
  top:0px!important;
  padding:10px 35px;
  font-size: 15px;
}
  @media (max-width: 767px) and (orientation: landscape) {
    .jumbotron {
      min-height: 500px; } }
  .jumbotron .container {
    position: relative;
    z-index: 1; }
  .jumbotron:before {
    position: absolute;
    display: block;
    content: "";
    opacity: 0.8;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
/*    background: #062261;*/
    /* fallback for old browsers */
  /*  background: -webkit-linear-gradient(to bottom right, #0c2c55 0%, #aa17a6 74%);
    background: linear-gradient(to bottom right, #0c2c55 0%, #aa17a6 74%);*/
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }
  .jumbotron h1 {
    line-height: .9;
    font-weight: bold;
    display: inline-block;
    /* border: 5px solid #fff; */
    font-size: 9rem;
    opacity: 0;
    animation: 1s fadeInFromTop cubic-bezier(0.785, 0.135, 0.15, 0.86) 1s forwards;
    animation-delay: .8s; 
    border-radius: 10px;
  }

.jumbotron-single h1 {
  font-size: 3rem !important; }

/*.overlay {
  color: #fff; }
  .overlay:before {
    position: absolute;
    display: block;
    content: "";
    opacity: 0.9;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--thm-green);
    background: -webkit-linear-gradient(to right, #333399, var(--thm-green));
    background: linear-gradient(to right, #333399, var(--thm-green));}
  .overlay.bg-white {
    color: #000; }
    .overlay.bg-white:before {
      background: #fff;
      opacity: .7; }*/
.text-black{
  color: #000 !important;
  text-align: justify;
}
.text-white {
  color: #fff !important; }

/*==============================
=            BUTTON            =
==============================*/
.btn {
  /* font-size: 14px;
  font-weight: 600; */
  border-radius: 30px;
  padding: 9px 25px;
  white-space: nowrap;
  text-transform: capitalize;
  transition: all 0.3s ease 0s; }

.btn-primary {
  display: inline-block;
  background-color: var(--thm-green);
  border-color: #24c82c;
  color: #fff; }
  .btn-primary:hover {
    background-color: var(--thm-orange);
    border-color: #39cd41;
    color: #fff; }

.btn-outline-primary {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff; }
  .btn-outline-primary:hover {
    background-color: var(--thm-green);
    border-color: var(--thm-green);
    color: #fff; }

.btn-shadow {
  box-shadow: -5px 8px 20px 0px rgba(229, 16, 2, 0.15); }

.btn-icon {
  color: #000; }

.btn-link {
  color: var(--thm-green); }

/*=====  End of BUTTON  ======*/
/*==========================================
=            FORM RECTANGLE ETC            =
==========================================*/
.rectangle-1 {
  width: 2000px;
  height: 400px;
  background: #8E2DE2;
  background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2);
  background: linear-gradient(to right, #4A00E0, #8E2DE2);
  transform-origin: 50% 150% 0;
  position: absolute;
  left: 0;
  z-index: 0;
  transform: scale(1) rotate(-10deg); }

.rectangle-2 {
  width: 2000px;
  height: 400px;
  background: #8E2DE2;
  background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2);
  background: linear-gradient(to right, #4A00E0, #8E2DE2);
  transform-origin: 65% 100% 0;
  position: absolute;
  right: 0;
  z-index: 0;
  transform: scale(1) rotate(50deg); }

@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateX(-100vw) rotate(-15deg); }
  to {
    opacity: 1;
    transform: translateX(0) rotate(-15deg); } }

@keyframes grow1 {
  from {
    opacity: 0;
    transform: scale(2) rotate(-10deg); }
  to {
    opacity: 1;
    transform: scale(1) rotate(-10deg); } }

@keyframes grow2 {
  from {
    opacity: 0;
    transform: scale(2) rotate(50deg); }
  to {
    opacity: 1;
    transform: scale(1) rotate(50deg); } }

@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px); }
  to {
    opacity: 1;
    transform: translateY(0); } }

.rectangle-1 {
  opacity: 0;
  animation: grow1 ease 1s forwards;
  box-shadow: 0px 20px 30px 0px rgba(9, 21, 54, 0.25) !important; }

.rectangle-2 {
  opacity: 0;
  animation: grow2 ease 1s forwards;
  box-shadow: 0px 20px 30px 0px rgba(9, 21, 54, 0.25) !important; }

.rectangle-transparent-1 {
  width: 500px;
  height: 500px;
  border: 15px solid rgba(255, 255, 255, 0.08);
  position: absolute;
  left: -5%;
  bottom: -10%;
  display: block;
  animation: floating-slow ease-in-out 12s infinite; }

.rectangle-transparent-2 {
  width: 600px;
  height: 600px;
  border: 15px solid rgba(255, 255, 255, 0.08);
  position: absolute;
  right: -10%;
  top: 5%;
  display: block;
  animation: floating-slow ease-in-out 12s infinite; }

.circle-1 {
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  position: absolute;
  display: block;
  border-radius: 50%;
  transform-origin: 50% 50%;
  left: 5%;
  top: 50%;
  animation: fadeInFromTop .5s linear forwards, floating ease 4s infinite; }

.circle-2 {
  width: 70px;
  height: 70px;
  top: 20%;
  left: 83%;
  border: 2px solid #fff;
  position: absolute;
  display: block;
  border-radius: 50%;
  transform-origin: 50% 50%;
  animation: fadeInFromTop .5s linear forwards, floating ease-in-out 4s infinite; }

.circle-3 {
  top: 80%;
  right: 25%;
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  position: absolute;
  display: block;
  border-radius: 50%;
  animation: fadeInFromTop .5s linear forwards, floating ease-in-out 4s infinite; }

@keyframes floating {
  0% {
    transform: translate(0%, 0%) rotate(25deg); }
  25% {
    transform: translate(5%, 15%) rotate(25deg); }
  50% {
    transform: translate(10%, 5%) rotate(25deg); }
  75% {
    transform: translate(0%, 15%) rotate(25deg); }
  100% {
    transform: translate(0%, 0%) rotate(25deg); } }

@keyframes floating-slow {
  0% {
    transform: translate(0%, 0%) rotate(25deg); }
  25% {
    transform: translate(1%, 3%) rotate(25deg); }
  50% {
    transform: translate(2%, 1%) rotate(25deg); }
  75% {
    transform: translate(0%, 3%) rotate(25deg); }
  100% {
    transform: translate(0%, 0%) rotate(25deg); } }

.triangle {
  position: absolute; }

.triangle-1 {
  right: 0;
  animation: fadeInFromTop .5s linear forwards, floating ease-in-out 6s infinite; }
  .triangle-1 img {
    height: 50px;
    width: 50px;
    transform: rotate(30deg); }

.triangle-2 {
  top: 30%;
  left: 20%;
  animation: fadeInFromTop .5s linear forwards, floating ease-in-out 8s infinite; }
  .triangle-2 img {
    width: 75px;
    height: 75px;
    transform: rotate(15deg); }

.triangle-3 {
  top: 80%;
  left: 15%;
  animation: fadeInFromTop .5s linear forwards, floating ease-in-out 10s infinite; }
  .triangle-3 img {
    width: 45px;
    height: 45px;
    transform: rotate(40deg); }

.triangle-4 {
  top: 60%;
  right: 15%;
  animation: fadeInFromTop .5s linear forwards, floating ease-in-out 5s infinite; }
  .triangle-4 img {
    width: 45px;
    height: 45px;
    transform: rotate(-40deg); }

/*=====  End of FORM RECTANGLE ETC  ======*/
/*================================
=            FEATURES            =
================================*/
.feature-item {
  transition: all .2s ease-in-out 0s !important; background-color: #fff;}
  .feature-item:hover {
    transform: translateY(-13px); }

.progress-bar {
  background-color: var(--thm-green); }


.feature-item p{
  letter-spacing: .2px;
}
/*=====  End of FEATURES  ======*/
/*=============================
=            TESTI            =
=============================*/
p{
  font-weight: 400;
}
.testi-img img {
  height: 80px;
  width: 80px !important;
  border-radius: 50%;
  display: inline-block !important; 
  border:1px solid #ccc;
}

.testi-details {
  text-align: center; 
  display: block;
}

.testi-text {
  font-weight: 400;
  padding-top: 50px;
  font-size: 20px;
  line-height: 30px;
  padding-bottom: 50px; }

.testi-icon {
  color: #81ff01
}
.testi-author{
  font-size: 18px;
}
.testi-content {
  margin-top: 50px;
  padding-bottom: 20px; }

.testi-item {
  margin-left: 15px;
  margin-right: 15px; }


.owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 18px!important;
  height: 18px!important;
  cursor: pointer; }
  .owl-dot span {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none;
    border:2px solid #001655!important;
    border-radius: 50%;
    background-image: linear-gradient(to right, #a2ff00,#ffff00,#ffb200)!important;
    text-indent: -999em;
    cursor: pointer;
    position: absolute;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
    -webkit-transition: box-shadow 0.3s ease, background-color 0.3s ease;
    transition: box-shadow 0.3s ease, background-color 0.3s ease; }
  .owl-dot.active > span {
    background-color: transparent!important;
    background-image: linear-gradient(to right, #fff,#fff,#fff)!important;
/*    box-shadow: 0 0 0 2px #001655*/
  }

.owl-dots {
  position: absolute;
  bottom: 0;
  display: block;
  text-align: center;
  left: 0;
  right: 0;
  clear: both;
  padding: 0;
  list-style: none;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.heading-section {
  position: relative; }

/*=====  End of TESTI  ======*/
/*=================================
=            PORTFOLIO            =
=================================*/
.filter-button-group {
  margin-bottom: 15px; }
  .filter-button-group a {
    margin: 5px;
    cursor: pointer; }

.grid-link a {
  margin: 5px;
  font-size: 20px;
  color: #000; }

.grid-portfolio {
  overflow: hidden; }

.gutter-sizer {
  width: 2%; }

.grid-sizer,
.grid-item {
  width: 32%; }

.grid-item {
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden; }
  .grid-item:hover .grid-info {
    top: 50%;
    opacity: 1; }

.grid-item-wrapper:before {
  content: '';
  display: block;
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all .3s ease;
  opacity: 0; }

.grid-item-wrapper:hover:before {
  opacity: 1; }

.grid-info {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .3s ease; }

.lightcase-icon-close:before {
  content: '\e870';
  font-family: "Linearicons-Free"; }

.grid-title {
  text-align: center; }

/*=====  End of PORTFOLIO  ======*/
/*===============================
=            SideNav            =
===============================*/
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1002;
  top: 0;
  left: 0;
  background-color: #001655;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  color: #fff; }

.sidenav-content {
  padding: 8px 32px;
  transform: translateY(20px);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86), opacity 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86); }

.in .sidenav-content {
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.5s, opacity 0.5s 0.5s; }
.sidemenu ul{
  list-style:none;
}
.sidenav a {
  text-decoration: none;
  list-style: none;
  font-size: 18px;
  color: #fff;
  display: block;
  padding:10px 10px;
  transition: 0.3s; }

.sidenav a:hover {
  color: #efff00; }
  .sidenav ul li::marker{
    display:none;
  }
.sidenav #side-nav-close,
.sidenav #side-search-close {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px; }

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px; }
  .sidenav a {
    font-size: 18px; } }

#side-search .form-control {
  background-color: transparent; }

#side-search .input-group-text {
  background: transparent;
  color: #fff; }

/*==============================
=            SKILLS            =
==============================*/
.skill-item {
  width: 100%;
  position: relative;
  padding: 10px 0; }
  .skill-item p {
    font-size: 16px;
    text-transform: uppercase;
    display: inline-block;
    font-weight: 500; }
  .skill-item .text-muted {
    color: #939393; }

.progress-bar, .progress {
  transition: all 4s; }

/*=====  End of SKILLS  ======*/
/*============================
=            BLOG            =
============================*/
.blog-item {
  transition: all 0.3s ease-in-out;
  background-color: #f7f8f9;
  border-radius: 3px;
  overflow: hidden; }
  .blog-item:hover {
    box-shadow: 0 5px 15px -5px #333;
    transform: translateY(-6px);
    -webkit-transform: translateY(-6px);
    -moz-transform: translateY(-6px);
    transform: translateY(-6px);
    box-shadow: 0 7px 10px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 7px 10px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 7px 10px rgba(0, 0, 0, 0.15); }

.bg-grey .blog-item {
  background-color: #fff; }

.blog-item-wrapper {
  margin-bottom: 30px; }

.blog-img img {
  width: 100%;
  height: 250px;
  object-fit: cover; }

.blog-title h4 {
  color: #000; }

.blog-text {
  padding: 15px; }

.blog-tag {
  color: #000;
  letter-spacing: 1.1px;
  text-transform: uppercase; }

.blog-meta {
  color: #939393; }
  .blog-meta p {
    display: inline-block;
    font-size: 14px; }
  .blog-meta a {
    color: #939393; }

.blog-author {
  display: inline-block;
  color: #939393; }
  .blog-author p {
    font-size: 14px;
    margin-bottom: 0; }

.blog-share-wrapper {
  float: right;
  display: inline-block;
  margin: 0 -5px; }
  .blog-share-wrapper .blog-share {
    padding: 0 5px;
    cursor: pointer; }

.blog-content img.float-left {
  margin: 15px 15px 15px 0; }

.blog-content .img.float-right {
  margin: 15px 0 15px 15px; }

/*=====  End of BLOG  ======*/
/*====================================
=            FORM CONTROL            =
====================================*/
.form-control:focus {
  border-color: var(--thm-green);
  box-shadow: 0 0 0 0.2rem rgba(253, 101, 90, 0.25); }

.form-control::-webkit-input-placeholder {
  opacity: .5; }

.form-control::-moz-placeholder {
  opacity: .5; }

.form-control:-ms-input-placeholder {
  opacity: .5; }

.form-control::-ms-input-placeholder {
  opacity: .5; }

.form-control::placeholder {
  opacity: .5; }

/*=====  End of FORM CONTROL  ======*/
/*==============================
=            CLIENT            =
==============================*/
.client-slider .owl-nav {
  display: none; }

.client-slider .client-item {
  padding: 0 40px; }

/*=====  End of CLIENT  ======*/
/*==================================
=            RESPONSIVE            =
==================================*/
@media (max-width: 992px) {
  .not-on-top #header-navbar {
    position: fixed;
    top: 0;
    height: auto;
    width: 100%;
    background: var(--thm-green);
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #333399, var(--thm-green));
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #333399, var(--thm-green));
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    z-index: 11; }
  #header-navbar {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .navbar-transparent .navbar-collapse {
    background: var(--thm-green);
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #333399, var(--thm-green));
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #333399, var(--thm-green));
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 10px; } }

@media (max-width: 767px) {
  .jumbotron h1 {
    font-size: 6rem; }
  .grid-item {
    width: 100%; }
  .progress {
    height: 30px;
    font-size: 16px; } }


.nav-mastfoot a.nav-link{
  color: #001655!important;
}
.nav-mastfoot a.nav-link:hover{
  color: var(--thm-green)!important;
}
/*=====  End of RESPONSIVE  ======*/


.subscribeinput{
    background-color: #fff;
    border: 0px;
    height: 55px;
    border-radius: 50px;
    padding: 10px 10px 10px 20px;
    max-width: 400px;
    width: 100%;
}
.subsribebtn{
  position: absolute;
  left:68%;
  top: 7px;
}

/* ---- stats.js ---- */



/* ---- particles.js container ---- */
.feature-item i{
  color: var(--thm-green);
}
.subscriptionbox{
  background-color: #fff;
/*  background-color: #f5f5f5;*/
  padding:40px 0px 50px 0px
}
.subscriptionbox ul{
  margin:0px;
  padding: 0px;
  list-style: none;
  counter-reset: section;
  display: flex;
  flex-wrap: wrap;
}
.subscriptionbox ul li{
  margin:0px;
  padding: 0px 0px 25px 0px;
  font-weight: 400;
  text-align: left;
  color: #333;
  flex:0 0 50%;
}
.subscriptionbox ul li:before{
  counter-increment: section;
  content: counter(section);
  width: 50px;
  height: 50px;
  display: block;
  border-radius: 100%;
  background-color: var(--subscr);
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-weight: 600;
  font-size: 30px;
  float: left;
  margin-bottom: 50px;
  margin-right: 30px;
}
#particles-js{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position:absolute;
}
#stats{
  display:none;
}

:root{
  --subscr:#68b90b;
}

.pricingTable{
  color: var(--subscr);
  background: #fff;
  text-align: center;
  padding: 20px 0 30px;
  margin: 0 15px;
  min-height: 310px;
}
.pricingTable .pricingTable-header{ margin: 0 0 25px; height: 200px; }
.pricingTable .title{
  color:#fff;
  background: var(--subscr);
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 15px 25px;
  margin: 0 -15px 15px;
  border-radius: 5px 5px 5px 0;
  position: relative;
}
.pricingTable .title:after{
  content: '';
  background: linear-gradient(to top right,transparent 50%, #444 50%);
  height: 15px;
  width: 15px;
  position: absolute;
  left: 0;
  top: 100%;
}
.pricingTable .price-value{
  padding: 0 0 0 70px;
  display: inline-block;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}
.pricingTable .price-value span{ display: block; }
.pricingTable .price-value .price-currency{
  color: #fff;
  background-color: var(--subscr);
  font-size: 35px;
  line-height: 60px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 5px;
}
.pricingTable .price-value .amount{
  color: #000;
  font-size: 35px;
  font-weight: 500;
  line-height: 45px;
  padding: 0 20px;
  margin: 3px 0 0px;
  border-top: 2px solid var(--subscr);
}
.pricingTable .price-value .duraton{
  color: #666;
  font-size: 15px;
  font-weight: 400;
}
.pricingTable .pricing-content{
  text-align: left;
  padding: 0;
  margin: 0 0 35px;
  list-style: none;
  display: inline-block;
}
.pricingTable .pricing-content li{
  color: #666;
  font-size: 17px;
  font-weight: 400;
  line-height: 35px;
  padding: 0 15px 0 25px;
  margin: 0 0 10px;
  position: relative;
}
.pricingTable .pricing-content li:last-child{ margin: 0; }
.pricingTable .pricing-content li:before{
  content: "";
  background-color: var(--subscr);
  height: 10px;
  width: 10px;
  border-radius: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  top: 50%;
}
.pricingTable .pricing-content li.disable:before{ background-color: #999; }
.pricingTable .pricingTable-signup a{
  color:#fff;
  background: linear-gradient(to top right,#eeaf00,#68b90b);
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 5px 45px;
  border-radius: 30px;
  display: inline-block;
  transition: all 0.3s ease 0s;
}
.pricingTable .pricingTable-signup a:hover{
  text-shadow: -2px 2px 1px rgba(0,0,0,0.4);
  box-shadow: 5px 5px 0 rgba(0,0,0,0.12);
}
.pricingTable.darkblue{ --subscr: #dd6800; }
.pricingTable.darkblue .pricingTable-signup a{
  background: linear-gradient(to top right,#F3482B,#E3B331);
}


/* subscription plans */

.subscriptionTable{
  color: #fff;
  background-color: #294046;
  font-family: 'Roboto Condensed', sans-serif;
  text-align: center;
  margin: 0 15px 80px;
  position: relative;
}
.subscriptionTable:before,
.subscriptionTable:after{
  content: "";
  background: #294046;
  width: 100%;
  height: 65px;
  position: absolute;
  bottom: -65px;
  left: 0;
  z-index: 1;
  clip-path: polygon(0 0, 100% 0, 100% 0%, 50% 100%, 0% 0%);
}
.subscriptionTable:after{
  background: #68b90b;
  width: 90%;
  height: 80px;
  transform: translateX(-50%);
  bottom: -80px;
  left: 50%;
  z-index: 0;
  clip-path: polygon(0 0, 100% 0, 100% 25%, 50% 100%, 0% 25%);
}
.subscriptionTable .subscriptionTable-header{ padding: 15px 0 12px; }
.subscriptionTable .title{ 
  font-size: 16px;
  font-weight: 600;
  padding: 0 23px;
  margin: 0;
  display: inline-block;
  position: relative;
}
.subscriptionTable .selectbox{
  border:0px solid #fff;
  background-color: rgba(255,255,255,0);
  padding: "5px";
  color:"#fff" !important;
}
.subscriptionTable .selectbox option{
  background-color: rgba(255,255,255,0);
}
.subscriptionTable .title:before,
.subscriptionTable .title:after{
  content: "";
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: 0;
}
.subscriptionTable .title:after{
  left: auto;
  right: 0;
}
.subscriptionTable .price-value{
  background: #68b90b;
  padding: 15px 0 13px;
  margin: 0 -15px;
  position: relative;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.subscriptionTable .price-value:before,
.subscriptionTable .price-value:after{
  content: "";
  background: linear-gradient(to right top, transparent 49%, #488600 50%);
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: -15px;
  left: 0;
}
.subscriptionTable .price-value:after{
  transform: rotateY(180deg);
  left: auto;
  right: 0;
}
.subscriptionTable .price-value .amount{
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  display: block;
}
.subscriptionTable .price-value .amount-sm{
  font-size: 22px;
  font-weight: 500;
  vertical-align: top;
  margin: -5px 0 0;
  display: inline-block;
}
.subscriptionTable .pricing-content{
  padding: 20px 0;
  margin: 0;
  list-style: none;
}
.subscriptionTable .pricing-content li{
  background: #1f3135;
  font-size: 17px;
  line-height: 33px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.subscriptionTable-signup{
  padding:20px 0px;
}
.subscriptionTable .pricing-content li:last-child{ margin: 0; }
.subscriptionTable .pricing-content li.disable{ color: #526164; }
.subscriptionTable .subscriptionTable-signup a{
  color: #fff;
  background: #68b90b;
  font-size: 18px;
  font-weight: 600;
  line-height: 45px;
  text-transform: uppercase;
  padding: 6px 25px;
  transition: all 0.3s;
}
.subscriptionTable .subscriptionTable-signup a:hover{
  text-shadow: -2px 2px 1px #000;
  box-shadow: 0 0 15px #000;
}
.subscriptionTable.blue:after,
.subscriptionTable.blue .price-value,
.subscriptionTable.blue .subscriptionTable-signup a{
  background: #dd6800;
}
.subscriptionTable.blue .price-value:before,
.subscriptionTable.blue .price-value:after{
  background: linear-gradient(to right top,transparent 49%, #b45500 50%);
}
.subscriptionTable.green:after,
.subscriptionTable.green .price-value,
.subscriptionTable.green .subscriptionTable-signup a{
  background: #05c46b;
}
.subscriptionTable.green .price-value:before,
.subscriptionTable.green .price-value:after{
  background: linear-gradient(to right top,transparent 49%, #058c4d 50%);
}

.pricingTable .price-value .amount small{
  font-size: 16px;
}

.lt-green{
  background-color: #f4ffe7;
}

.sidenav-content ul{
  margin:0px;
  padding:0px;
  list-style: none;
}
.sidenav-content ul li{
  padding:8px 0px;
}
.sidenav-content ul li a{
  font-size: 18px;
  color: #fff;
  font-weight: 400;
}
.sidenav-content ul li a:hover{
  color: var(--thm-yellow);
}

/*@media only screen and (max-width: 992px){
.subsribebtn{
  right:10px;
}
}
@media only screen and (max-width: 768px){
.subsribebtn{
  right:65px;
}
}
@media only screen and (max-width: 560px){
.subsribebtn{
  right:60px;
}
}
@media only screen and (max-width: 500px){
.subsribebtn{
  right:45px;
}
}
@media only screen and (max-width: 400px){
.subsribebtn{
  right:10px;
}
}*/

.abtsection{
  background: linear-gradient(to right,#83ff47, #f5ff5f, #ff9256);
}
.abtsection p{
  text-align: justify;
  font-size: 15px;
  color: #000!important;
}
.abtsection h2.section-title{
  color: #001655;
}
#section-featurettes p{
  font-weight: 600;
  font-size: 18px;
  padding-right: 40px;
  padding-bottom: 20px;
  color: #fff;
}
.planpackagebox{
  background-color: #e7e7e7;
  border-radius: 8px;
  padding:30px 30px 35px 30px;
  text-align: center
}
#subscriptions{
  padding:90px 0px;
}
#subscriptions .row .col-lg-4:nth-child(2) .planpackagebox{
  background-color: #f1e300;
  transform: scale(1,1.1);
}
.planpackagebox h4{
  font-weight: 700;
  font-size: 26px;
  color: #001655;
  position: relative;
}
.planpackagebox h4:after{
  width: 40px;
  height: 3px;
  background-color: #001655;
  content: '';
  position: absolute;
  bottom:-5px;
  left:123px;
}
.planpackagebox h3{
  margin:0px;
  padding:40px 0px 30px 0px;
  font-weight: 700;
  font-size: 32px;
  color: #001655;
  height: 180px;
  display: block;
  vertical-align: middle;
  overflow: hidden;
  text-align: center;
  line-height: 20px;
}
.planpackagebox h3 span{
  font-weight: 400;
  font-size: 17px;
  position: relative;
  bottom: 15px;
}
#features{
  width: 100%;
  height: 100%;
  background: url('iconfeaturesbg.jpg');
  /* background-repeat: no-repeat; */
  background-position: bottom center;
  background-size: cover;
}
#features h2.section-title{
  color: #f1e300!important;
}
#projects h2.section-title{
  text-align: center !important;
  color: #f1e300!important;
}
#landinProjects{

  margin: 0 100px;
}
.greenblock{
  background-color: #81ff01;
  height: 240px;
  padding:10px 20px 20px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  margin:15px;
}
.greenblock h4{
  margin:0px;
  padding:0px;
  font-size: 24px;
  font-weight: 600;
  color: #001655;
}
.greenblock img{
    width: 95px;
    height: 95px;
    margin:0px auto;
    margin-bottom: 10px;
    display: block;
}
#contact{
  background-color: #001655;
  padding:60px 0px 5px 0px;
}
#contact h2{
  color: #f1e300;
}
#contact .form-control{
  background-color: transparent!important;
  border-radius: 0px!important;
  border:0px!important;
  color: #fff!important;
  border-bottom: 1px solid #969fb9!important;
  margin-bottom: 20px!important;
}
#contact .form-control:focus{
  box-shadow: inherit;
}
#contact .form-control::-webkit-input-placeholder {
  color: #9fb1e3;
  font-size: 14px;
  font-weight: 400;
}
#contact .form-control::-moz-placeholder { 
  color: #9fb1e3;
  font-size: 14px;
  font-weight: 400;
}
#contact .form-control:-ms-input-placeholder { 
  color: #9fb1e3;
  font-size: 14px;
  font-weight: 400;
}
#contact .form-control:-moz-placeholder { 
  color: #9fb1e3;
  font-size: 14px;
  font-weight: 400;
}
#contact .btn-primary{
  color: #001655!important;
  text-transform: uppercase;
  font-size: 18px;
  border:2px solid #fff!important;
  background-image: linear-gradient(to right, #82ff00, #ebff00,#ff9600);
}
#contact .btn-primary:hover{
  background-image: linear-gradient(to right, #ff9600, #ebff00,#82ff00);
}
#contact h4{
  font-size: 18px;
  color: #fff;
}
#contact p{
  color: #fff;
  font-size: 15px;
}
.coniconbox{
  text-align: center;
  margin-bottom: 40px;
}
.coniconbox img{
  height: 60px;
}
.coniconbox h5{
  color: #f1e300;
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
}
#contact .btn-purchase{
  background-color: #f1e300;
  border:3px solid #fff;
  display: inline-block;
  border-radius: 30px;
   color: #001655;
   font-size: 15px;
   font-weight: 600;
   line-height:   30px;
  padding:5px 30px;
}
#contact .btn-purchase:hover{
  background-color: #001655 ;
  color: #fff;
}
.mastfoot{
  background-color: #001655;
  position: relative; 
}
.mastfoot .inner.container:before{
  content: "";
  width:  100%;
  max-width:  1100px;
  height:1px;
  position: absolute; 
  background-image: linear-gradient(to right, #82ff00, #ebff00,#ff9600);
}
.inner{
  padding-top:  10px;
}
.inner p{
  font-size:  12px;
  color: #fff;
}
.subinput{
  position: relative; 
}
.subinput h6{
  color: #fff;
  white-space: nowrap;
  padding-right:  20px;
}
.subinput .form-control{
    border-radius: 30px;
    border:1px solid #fff!important;
    height:   50px;
    color: #fff!important;
    background-color: transparent!important;
}
.subinput .form-control:focus{
  box-shadow: inherit;
}
.subinput button{
  background-color: #81ff01;
  border-radius: 30px;
  border:1px solid #fff;
  padding:5px 20px;
  position: absolute; 
  right:8px;
  z-index: 999;
}
.subinput button:hover{
  background-color: #f1e300;
  cursor: pointer;
}


@media only screen and (max-width: 1199px){
.mastfoot{
  top:-1px;
}
.mastfoot .inner.container:before{
  width: 80%;
}
}
@media only screen and (max-width: 992px){
.planpackagebox{
  margin-bottom: 30px;  
}
.planpackagebox h4:after{
  display: none;
}
#subscriptions{
  padding:40px 0px;
}
#features{
  background-size: cover;
}
}
@media only screen and (max-width: 576px){
  .mastfoot .inner.container:before{
  width: 95%;
}
 .mastfoot .inner .d-flex{
  display: block!important;
 }
.subinput button{
  top:34px;
}
}
.img__wrap {
  position: relative;
  height: 355px;
  width: 100%;
  /* margin: 15px; */
  margin-bottom: 50px !important;
  
}
.img__img{
  height: 355px !important;
  width: 100%; 
}
.img__description {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background: rgb(2 2 2 / 63%); */
  /* color: #000; */
  /* visibility: hidden; */
  /* opacity: 0; */
  padding:150px;
  font-weight: bold;
  /* background-color: #ffffff59 !important; */
  /* transition effect. not necessary */
  /* transition: opacity .2s, visibility .2s; */
}

.project-title p{
  /* padding-: 90px; */
  /* padding-left: 90px;
  padding-right: 62px;
  margin-top: 45px;
  background-color: white;
  margin-left: -138px !important;
  padding: 47px;
  color: #000 !important; */

  margin-top: 25px;
  background-color: #fffffff1 !important;
  margin-left: -112px !important;
  padding: 18px;
  font-size: 16px;
  color: #a5a5a5 !important;
  width: 388px;
  height: 205px !important;
}



.img__wrap:hover .img__description {
  visibility: visible;
  /* visibility: visible; */
  /* opacity: 1; */
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none; 
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
p {
  color: #3b3f5c !important;
}

.table-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0px 0px 20px 0px;
  padding: 0px 0px 10px 0px;
  position: relative;
}
.page-title-underline:after {
  content: "";
  width: 100%;
  height: 3px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to right, #00d9ff, #24ce26f4);
}
.page-sub-title-underline:after {
  content: "";
  width: 30%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to right, #00d9ff, #24ce26f4);
}
/* table desing <> */
.custom_table_design{
  width: 100%;
  
  }
.custom_table_design thead th {
text-align: center;

}
.custom_table_design thead tr{
 /* background-color: #d7d7d7; */
 background-image: linear-gradient(to right, #03a9c182 0%, #0ba360c4 100%) !important;
 text-align: center;
  }
  .custom_table_design tbody tr td{
    background-color: white;
    text-align: center;
     }
  .custom_table_design tbody tr {
    border-top: none !important;
    border-bottom: 3px solid #ececec !important;
  }
  .custom_table_design tr th:first-child,
.custom_table_design tr td:first-child {
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}

.custom_table_design tr th:last-child,
.custom_table_design tr td:last-child {
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}
.custom_table_design input {
  padding-top: 0.6rem !important;
}

  /* table desing </> */
  .status-primary{
    border: 1px solid #11b001;
      color: #11b001;
      background-color: #fff !important;
      border-radius: 3px;
      padding: 4px 4px;
      font-size: 11px;
  }
  .status-warning{
    border: 1px solid #ff9800;
      color: #ff9800;
      background-color: #fff !important;
      border-radius: 3px;
      padding: 4px 4px;
      font-size: 11px;
  }
  
.input_label{
  color: black !important;
  font-size: 12px !important;
  margin-left: 5px !important;
}  
input[disabled], select[disabled], textarea[disabled],
input[readonly], select[readonly], textarea[readonly] {
    cursor: not-allowed;
    background-color: #f1f2f3!important;
    color: #000000a8 !important
}
