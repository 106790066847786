.gm-style-iw + div {display: none;}
/* button.gm-ui-hover-effect {
    visibility: hidden;
} */

.colBlack{
    color:#000 !important;
}
.butC{
    background: #4ad600;
    color: #fff;
    border: 0px solid;
    border-radius: 500px;
    padding: 3px 24px;

}