body {
  background: url("../images/body-bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
}
body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
body::-webkit-scrollbar-thumb {
  background: #888;
}
body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.form-control {
  background-color: #f9f9f9 !important;
  border: 1px solid #c5cad2 !important;
  height: calc(1.8em + 0.9rem + 2px);
}
.form-control:focus {
  background-color: #fff !important;
}

.btn-primary {
  background-color: #ffff00;
  border: 1px solid #ffff00;
  color: #000;
  border-radius: 30px;
  padding-left: 30px;
  padding-right: 30px;
  text-transform: uppercase;
  box-shadow: 5px 2px 3px rgba(0, 0, 0, 0.3);
}
.btn-primary:hover, .btn-primary:focus {
  background-color: #ffcc00;
  border: 1px solid #ffcc00;
  color: #000;
}

.card.transparent {
  background-color: transparent;
  border: 0px;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(2.1rem + 2px);
  line-height: 1.25;
  background-color: rgba(151, 140, 140, 0.5) !important;
  border: 0px !important;
  border-radius: 7px;
  color: #fff;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  height: 100%;
  padding: 7px 10px 10px 15px;
  font-size: 12px;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}

.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}

.form-floating > .form-control::placeholder {
  color: transparent;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.6rem;
  padding-bottom: 0.6rem;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.6rem;
  padding-bottom: 0.6rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.6rem;
  padding-bottom: 0.6rem;
}

.form-floating > .form-select {
  border: 0px !important;
}
.form-floating > .form-select:focus-visible, .form-floating > .form-select:focus {
  border-color: inherit !important;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.col-form-label {
  color: #fff;
}

.radio label {
  color: #fff;
}

.tontxt {
  position: absolute;
  top: 7px;
  color: #dcd8d8;
  right: 36px;
}

.form-floating .form-select option {
  color: #333 !important;
  line-height: 20px;
}

.bootstrap-touchspin {
  width: 160px;
}
.bootstrap-touchspin .input-group-btn {
  height: 34px;
  background-color: rgba(151, 140, 140, 0.5) !important;
  color: #fff;
}
.bootstrap-touchspin .input-group-prepend {
  border-radius: 20px 0px 0px 20px;
}
.bootstrap-touchspin .input-group-append {
  border-radius: 0px 20px 20px 0px;
}
.bootstrap-touchspin .form-control {
  height: 34px;
}
.bootstrap-touchspin .btn-primary {
  background-color: transparent;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 10px;
  border: 0px !important;
  box-shadow: inherit !important;
  color: #fff;
}
.bootstrap-touchspin .btn-primary:hover, .bootstrap-touchspin .btn-primary:focus {
  background-color: #68b90b;
}

.form-floating > .form-select {
  padding-left: 10px;
  padding-right: 10px;
  background-position: right 30px !important;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown-item {
  color: #333 !important;
}

.navbar-custom .topnav-menu .nav-link {
  color: #fff !important;
  font-size: 16px;
}

#resptable table tr td {
  font-size: 12px;
}

ul.usernewstepsnomenu {
  margin: 0px;
  padding: 10px 0px 0px 0px;
  list-style: none;
  display: flex;
  flex-direction: column;
}
ul.usernewstepsnomenu li {
  text-align: right;
  position: relative;
  padding: 12px 40px 12px 0px;
}
ul.usernewstepsnomenu li a {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  transition: all ease-in 0.2s;
}
ul.usernewstepsnomenu li a i {
  opacity: 0;
  transition: all ease-in 0.2s;
}
ul.usernewstepsnomenu li a:hover {
  color: #ffff00;
}
ul.usernewstepsnomenu li a:hover i {
  opacity: 1;
}
ul.usernewstepsnomenu li a:hover span {
  opacity: 0;
}
ul.usernewstepsnomenu li a span {
  position: absolute;
  top: 10px;
  right: -20px;
  transition: all ease-in 0.5s;
}
ul.usernewstepsnomenu li a span.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  background-color: #fff;
  border: 3px solid #7fd61b;
  color: #d4d4d4;
  text-align: center;
  line-height: 35px;
  font-size: 14px;
  font-weight: 700;
}
ul.usernewstepsnomenu li a span.circle i {
  opacity: 1;
}
ul.usernewstepsnomenu li a span.circle:hover, ul.usernewstepsnomenu li a span.circle.active {
  background-color: #ffff00;
  color: #000;
}
ul.usernewstepsnomenu li a span.circle.completed {
  background-color: #68b90b;
  color: #fff;
}
ul.usernewstepsnomenu li.compstep a {
  color: #ffff00;
}

.form-floating > .form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../images/angle-down.svg") no-repeat;
  background-position: top 10px right 14px !important;
  background-size: 10px;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #68b90b;
  border-color: #68b90b;
}

.yellowcol {
  color: #ffff00 !important;
  font-size: 16px;
}

.rdbg {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px 10px 1px 20px;
  border-radius: 30px;
  border: 1px solid #000;
  margin-bottom: 10px;
}

.table td {
  padding: 0.65rem 0.65rem 0.4rem 0.65rem;
}

.table th {
  padding: 0.65rem;
}

.table-blk {
  background-color: rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
}

.table thead th, .table td {
  border: 0px !important;
  vertical-align: middle !important;
  color: #fff;
}

.bg-success {
  background: #68b90b !important;
  color: #fff;
}

.page-title.fz-16 {
  font-size: 16px !important;
  line-height: 24px;
  padding-bottom: 10px;
}

.page-title a {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px;
}
.page-title a:hover {
  color: #ffff00;
}

.yellowcol {
  color: #fff !important;
  font-size: 16px;
  font-weight: 600;
}

.desoprbox {
  text-align: center;
  color: #fff;
  width: 250px !important;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  display: table-cell;
  border: 1px solid #000;
  border-radius: 5px;
}
.desoprbox p {
  margin: 0px;
  padding: 0px;
  color: #fff;
  font-size: 16px;
}
.desoprbox i {
  font-size: 30px;
  color: #68b90b;
}

.desoprbox:hover p {
  color: #ffff00;
}
.desoprbox:hover i {
  color: #ffff00;
}

a.checkactive {
  position: relative;
}

a.checkactive .active {
  border: 1px solid #ffff00;
}
a.checkactive .active:after {
  content: "\e83f";
  font-family: "feather" !important;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 20px;
  border-radius: 50%;
  background-color: #68b90b;
  position: absolute;
  right: -5px;
  bottom: -5px;
  border: 2px solid #fff;
}

a.checkactive .desoprbox {
  background-color: rgb(0, 0, 0);
}

.trspbg {
  height: calc(2.1rem + 2px);
  line-height: 1.25;
  background-color: rgba(151, 140, 140, 0.5) !important;
  border: 0px !important;
  border-radius: 20px;
  color: #fff !important;
}
.trspbg:focus {
  background-color: rgba(151, 140, 140, 0.5) !important;
}

.prfbox {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid #000;
  border-radius: 5px;
  padding: 5px 5px 5px 10px;
}
.prfbox h5 {
  color: #fff;
  width: 125px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.prfbox p {
  color: #ffff00;
  width: 125px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.profilepg .card {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}
.profilepg .card-body {
  padding: 0.5rem 1rem;
}

.trasparent-box {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 20px;
}
.trasparent-box h3 {
  font-size: 20px;
}
.trasparent-box h4 {
  text-align: center;
}
.trasparent-box h5 {
  font-size: 24px;
  font-weight: 300;
}
.trasparent-box .border-bottom {
  border-bottom: 1px solid rgba(255, 255, 244, 0.2) !important;
}

.yellowcol {
  color: #ffff00 !important;
}

/* subscription plans */
.subscriptionTable {
  color: #fff;
  background-color: #294046;
  font-family: "Roboto Condensed", sans-serif;
  text-align: center;
  margin: 0 15px 80px;
  position: relative;
}

.subscriptionTable:before,
.subscriptionTable:after {
  content: "";
  background: #294046;
  width: 100%;
  height: 65px;
  position: absolute;
  bottom: -65px;
  left: 0;
  z-index: 1;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 0%, 50% 100%, 0% 0%);
          clip-path: polygon(0 0, 100% 0, 100% 0%, 50% 100%, 0% 0%);
}

.subscriptionTable:after {
  background: #68b90b;
  width: 90%;
  height: 80px;
  transform: translateX(-50%);
  bottom: -80px;
  left: 50%;
  z-index: 0;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 25%, 50% 100%, 0% 25%);
          clip-path: polygon(0 0, 100% 0, 100% 25%, 50% 100%, 0% 25%);
}

.subscriptionTable .subscriptionTable-header {
  padding: 15px 0 12px;
}

.subscriptionTable .title {
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 23px;
  margin: 0;
  display: inline-block;
  position: relative;
}

.subscriptionTable .title:before,
.subscriptionTable .title:after {
  content: "";
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: 0;
}

.subscriptionTable .title:after {
  left: auto;
  right: 0;
}

.subscriptionTable .price-value {
  background: #68b90b;
  padding: 15px 0 13px;
  margin: 0 -15px;
  position: relative;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.subscriptionTable .price-value:before,
.subscriptionTable .price-value:after {
  content: "";
  background: linear-gradient(to right top, transparent 49%, #488600 50%);
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: -15px;
  left: 0;
}

.subscriptionTable .price-value:after {
  transform: rotateY(180deg);
  left: auto;
  right: 0;
}

.subscriptionTable .price-value .amount {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  display: block;
}

.subscriptionTable .price-value .amount-sm {
  font-size: 22px;
  font-weight: 500;
  vertical-align: top;
  margin: -5px 0 0;
  display: inline-block;
}

.subscriptionTable .pricing-content {
  padding: 20px 0;
  margin: 0;
  list-style: none;
}

.subscriptionTable .pricing-content li {
  background: #1f3135;
  font-size: 17px;
  line-height: 33px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.subscriptionTable-signup {
  padding: 20px 0px;
}

.subscriptionTable .pricing-content li:last-child {
  margin: 0;
}

.subscriptionTable .pricing-content li.disable {
  color: #526164;
}

.subscriptionTable .subscriptionTable-signup a {
  color: #fff;
  background: #68b90b;
  font-size: 25px;
  font-weight: 600;
  line-height: 45px;
  text-transform: uppercase;
  padding: 6px 25px;
  transition: all 0.3s;
}

.subscriptionTable .subscriptionTable-signup a:hover {
  text-shadow: -2px 2px 1px #000;
  box-shadow: 0 0 15px #000;
}

.subscriptionTable.blue:after,
.subscriptionTable.blue .price-value,
.subscriptionTable.blue .subscriptionTable-signup a {
  background: #dd6800;
}

.subscriptionTable.blue .price-value:before,
.subscriptionTable.blue .price-value:after {
  background: linear-gradient(to right top, transparent 49%, #b45500 50%);
}

.subscriptionTable.green:after,
.subscriptionTable.green .price-value,
.subscriptionTable.green .subscriptionTable-signup a {
  background: #05c46b;
}

.subscriptionTable.green .price-value:before,
.subscriptionTable.green .price-value:after {
  background: linear-gradient(to right top, transparent 49%, #058c4d 50%);
}

textarea.form-control {
  height: inherit !important;
}

ul.cmsmenu {
  margin: 0px;
  padding: 10px 0px 20px 0px;
  list-style: none;
}
ul.cmsmenu li {
  margin: 0px;
  padding: 0px;
  display: inline-block;
  padding-right: 10px;
}
ul.cmsmenu li a {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px;
}
ul.cmsmenu li a:hover {
  background-color: #ffff00;
  color: #000;
  border-color: #ffff00;
}
ul.cmsmenu li.active a {
  background-color: #ffff00;
  color: #000;
  border-color: #ffff00;
}

.cmsnewblock p {
  color: #fff;
  text-align: justify;
}
.cmsnewblock h1, .cmsnewblock h2, .cmsnewblock h3, .cmsnewblock h4, .cmsnewblock h5, .cmsnewblock h6 {
  margin: 0px;
  padding: 0px 0px 10px;
  color: #fff;
}
.cmsnewblock ul li, .cmsnewblock ol li {
  color: #fff;
}
.cmsnewblock a {
  color: #ffff00;
}
.cmsnewblock a:hover {
  color: #fff;
}

.btn-success {
  background-color: #68b90b;
  border-color: #68b90b;
}

.table tr th {
  border-top: 0px;
}

.table.projecttable {
  border: 1px solid #fff !important;
}
.table.projecttable tr {
  background: rgba(0, 0, 0, 0.5);
}
.table.projecttable tr th {
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  color: #ffff00;
}
.table.projecttable tr td {
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}/*# sourceMappingURL=userdash-newstyles.css.map */